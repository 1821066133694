import { restActionCreatorHelper } from "./../../helpers/restActionCreatorHelper";

const userRestAction = restActionCreatorHelper(`user`);

export const UserLoginRestActions = {
  request: "user/login_request",
  success: "user/login_success",
  failure: "user/login_failure",
  needUpdate: "user/login_needUpdate",
} as const;

export const UserLogoutRestActions = {
  request: "user/logout_request",
  success: "user/logout_success",
  failure: "user/logout_failure",
  needUpdate: "user/logout_needUpdate",
} as const;

export const GetPlayersRestActions = {
  request: "user/players_request",
  success: "user/players_success",
  failure: "user/players_failure",
  needUpdate: "user/players_needUpdate",
} as const;

export const GetSponsorsRestActions = {
  request: "user/sponsors_request",
  success: "user/sponsors_success",
  failure: "user/sponsors_failure",
  needUpdate: "user/sponsors_needUpdate",
} as const;

export const EditPlayersRestActions = {
  request: "user/editplayers_request",
  success: "user/editplayers_success",
  failure: "user/editplayers_failure",
  needUpdate: "user/editplayers_needUpdate",
} as const;

export const CreateSponsorRestActions = {
  request: "user/createsponsor_request",
  success: "user/createsponsor_success",
  failure: "user/createsponsor_failure",
  needUpdate: "user/createsponsor_needUpdate",
} as const;

export const EditSponsorsRestActions = {
  request: "user/editsponsors_request",
  success: "user/editsponsors_success",
  failure: "user/editsponsors_failure",
  needUpdate: "user/editsponsors_needUpdate",
} as const;

export const DeleteSponsorRestActions = {
  request: "user/deletesponsor_request",
  success: "user/deletesponsor_success",
  failure: "user/deletesponsor_failure",
  needUpdate: "user/deletesponsor_needUpdate",
} as const;

export const EditFixturesRestActions = {
  request: "user/editfixtures_request",
  success: "user/editfixtures_success",
  failure: "user/editfixtures_failure",
  needUpdate: "user/editfixtures_needUpdate",
} as const;

export const GetFixturesRestActions = {
  request: "user/getfixtures_request",
  success: "user/getfixtures_success",
  failure: "user/getfixtures_failure",
  needUpdate: "user/getfixtures_needUpdate",
} as const;

export const GetClubsRestActions = {
  request: "user/getclubs_request",
  success: "user/getclubs_success",
  failure: "user/getclubs_failure",
  needUpdate: "user/getclubs_needUpdate",
} as const;

export const GetSocialRestActions = {
  request: "user/getsocial_request",
  success: "user/getsocial_success",
  failure: "user/getsocial_failure",
  needUpdate: "user/getsocial_needUpdate",
} as const;

export const EditSocialRestActions = {
  request: "user/editsocial_request",
  success: "user/editsocial_success",
  failure: "user/editsocial_failure",
  needUpdate: "user/editsocial_needUpdate",
} as const;

export const DeleteClubRestActions = {
  request: "user/deleteclub_request",
  success: "user/deleteclub_success",
  failure: "user/deleteclub_failure",
  needUpdate: "user/deleteclub_needUpdate",
} as const;

export const GetEventsRestActions = {
  request: "user/getevents_request",
  success: "user/getevents_success",
  failure: "user/getevents_failure",
  needUpdate: "user/getevents_needUpdate",
} as const;

export const CreateEventRestActions = {
  request: "user/createevent_request",
  success: "user/createevent_success",
  failure: "user/createevent_failure",
  needUpdate: "user/createevent_needUpdate",
} as const;

export const GetSelfRestActions = {
  request: "user/get_self_request",
  success: "user/get_self_success",
  failure: "user/get_self_failure",
  needUpdate: "user/get_self_needUpdate",
} as const;

export const DeleteEventRestActions = {
  request: "user/deleteevent_request",
  success: "user/deleteevent_success",
  failure: "user/deleteevent_failure",
  needUpdate: "user/deleteevent_needUpdate",
} as const;

export const EditEventRestActions = {
  request: "user/editevent_request",
  success: "user/editevent_success",
  failure: "user/editevent_failure",
  needUpdate: "user/editevent_needUpdate",
} as const;

export const VoteSearchRestActions = {
  request: "user/votesearch_request",
  success: "user/votesearch_success",
  failure: "user/votesearch_failure",
  needUpdate: "user/votesearch_needUpdate",
} as const;

export const GetPredictionsRestActions = {
  request: "user/getpredicitons_request",
  success: "user/getpredicitons_success",
  failure: "user/getpredicitons_failure",
  needUpdate: "user/getpredicitons_needUpdate",
} as const;

export const GetUsersRestActions = {
  request: "user/getusers_request",
  success: "user/getusers_success",
  failure: "user/getusers_failure",
  needUpdate: "user/getusers_needUpdate",
} as const;

export const EditClubRestActions = {
  request: "user/editclub_request",
  success: "user/editclub_success",
  failure: "user/editclub_failure",
  needUpdate: "user/editclub_needUpdate",
} as const;

export const DeleteUserRestActions = {
  request: "user/deleteuser_request",
  success: "user/deleteuser_success",
  failure: "user/deleteuser_failure",
  needUpdate: "user/deleteuser_needUpdate",
} as const;

export const GetClubMembersRestActions = {
  request: "user/getclubmembers_request",
  success: "user/getclubmembers_success",
  failure: "user/getclubmembers_failure",
  needUpdate: "user/getclubmembers_needUpdate",
} as const;

export const GetEventMembersRestActions = {
  request: "user/geteventmembers_request",
  success: "user/geteventmembers_success",
  failure: "user/geteventmembers_failure",
  needUpdate: "user/geteventmembers_needUpdate",
} as const;

export const GetClubEventsRestActions = {
  request: "user/getclubevents_request",
  success: "user/getclubevents_success",
  failure: "user/getclubevents_failure",
  needUpdate: "user/getclubevents_needUpdate",
} as const;

export const GetClubEventsByIdRestActions = {
  request: "user/getclubeventsbyid_request",
  success: "user/getclubeventsbyid_success",
  failure: "user/getclubeventsbyid_failure",
  needUpdate: "user/getclubeventsbyid_needUpdate",
} as const;

export const DeleteClubEventRestActions = {
  request: "user/deleteclubevent_request",
  success: "user/deleteclubevent_success",
  failure: "user/deleteclubevent_failure",
  needUpdate: "user/deleteclubevent_needUpdate",
} as const;

export const DeleteClubMemberRestActions = {
  request: "user/deleteclubmember_request",
  success: "user/deleteclubmember_success",
  failure: "user/deleteclubmember_failure",
  needUpdate: "user/deleteclubmember_needUpdate",
} as const;

export const DeleteEventMemberRestActions = {
  request: "user/deleteeventmember_request",
  success: "user/deleteeventmember_success",
  failure: "user/deleteeventmember_failure",
  needUpdate: "user/deleteeventmember_needUpdate",
} as const;

export const GetClubEventsListByIdRestActions = {
  request: "user/getclubeventslistbyid_request",
  success: "user/getclubeventslistbyid_success",
  failure: "user/getclubeventslistbyid_failure",
  needUpdate: "user/getclubeventslistbyid_needUpdate",
} as const;

export const SendNotificationRestActions = {
  request: "user/sendnotification_request",
  success: "user/sendnotification_success",
  failure: "user/sendnotification_failure",
  needUpdate: "user/sendnotification_needUpdate",
} as const;

export const UserTokenRelevanceCheckRestActions = userRestAction(
  "tokenRelevanceCheck"
);
