import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import homeFill from "@iconify/icons-eva/home-fill";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../../logic/user/UserRedux";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import footballIcon from "@iconify/icons-icon-park-outline/football";
import peopleFill from "@iconify/icons-eva/people-fill";
import shoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import personFill from "@iconify/icons-eva/person-fill";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";
import gridOutline from "@iconify/icons-eva/grid-outline";
import globe2Fill from "@iconify/icons-eva/globe-2-fill";
import copyFill from "@iconify/icons-eva/copy-fill";
import logo from "./assets/logo.png";
import Logo from "../../components/Logo";
import smilingFaceFill from "@iconify/icons-eva/smiling-face-fill";
import clipboardFill from "@iconify/icons-eva/clipboard-fill";
import bellFill from "@iconify/icons-eva/bell-fill";
import { userSelector } from "src/logic/user/UserSelectors";
import { Link as RouterLink } from "react-router-dom";
// material
import { alpha } from "@material-ui/core/styles";

import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
} from "@material-ui/core";
// components
import MenuPopover from "../../components/MenuPopover";
//
import account from "../../_mocks_/account";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Ссылки",
    icon: pieChart2Fill,
    linkTo: "/home/app",
  },
  {
    label: "Партнеры",
    icon: copyFill,
    linkTo: "/home/partners",
  },
  {
    label: "Клубы",
    icon: gridOutline,
    linkTo: "/home/clubs",
  },
  {
    label: "Ивенты",
    icon: clipboardFill,
    linkTo: "/home/clubevents",
  },
  {
    label: "День Матча",
    icon: globe2Fill,
    linkTo: "/home/events",
  },
  {
    label: "Состав",
    icon: peopleFill,
    linkTo: "/home/team",
  },
  {
    label: "Матчи",
    icon: footballIcon,
    linkTo: "/home/fixtures",
  },
  {
    label: "Прогноз",
    icon: pieChart2Fill,
    linkTo: "/home/predictions",
  },
  {
    label: "Юзеры",
    icon: smilingFaceFill,
    linkTo: "/home/users",
  },
  {
    label: "Нотификации",
    icon: bellFill,
    linkTo: "/home/notifications",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const dispatch = useDispatch();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const {
    profile
  } = useSelector(userSelector);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onLogout = () => {
    dispatch(UserActions.logout.request());
  };

  const style = {
    width: 25,
    height: 45,
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              position: "absolute",
            },
          }),
        }}>
        <img style={style} src={logo} alt="Logo" />

        {/* <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/home" sx={{ display: "inline-flex" }}>
          <img style={style} src={logo} alt="Logo" />
        </Box>
      </Box> */}
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {profile?.name ?? "Ruh Lviv"}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {profile?.email ?? "ruh@gmail.com"}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: "body2", py: 1, px: 2.5 }}>
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            onClick={() => {
              onLogout();
            }}
            fullWidth
            color="inherit"
            variant="outlined">
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
