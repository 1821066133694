import { Icon } from "@iconify/react";
import peopleFill from "@iconify/icons-eva/people-fill";
import calendar3Event from "@iconify/icons-bi/calendar3-event";
import footballIcon from "@iconify/icons-icon-park-outline/football";
import gridOutline from "@iconify/icons-eva/grid-outline";
import clockOutline from "@iconify/icons-eva/clock-outline";
import compassOutline from "@iconify/icons-eva/compass-outline";
import copyFill from "@iconify/icons-eva/copy-fill";
import globe2Fill from "@iconify/icons-eva/globe-2-fill";
import arrowCircleUpOutline from "@iconify/icons-eva/arrow-circle-up-outline";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import smilingFaceFill from "@iconify/icons-eva/smiling-face-fill";
import clipboardFill from "@iconify/icons-eva/clipboard-fill";
import bellFill from "@iconify/icons-eva/bell-fill";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "ссылки",
    path: "/home/app",
    icon: getIcon(compassOutline),
  },
  {
    title: "партнеры",
    path: "/home/partners",
    icon: getIcon(copyFill),
  },
  {
    title: "клубы",
    path: "/home/clubs",
    icon: getIcon(gridOutline),
  },
  {
    title: "Ивенты",
    path: "/home/clubevents",
    icon: getIcon(clipboardFill),
  },
  {
    title: "день матча",
    path: "/home/events",
    icon: getIcon(globe2Fill),
  },
  {
    title: "состав",
    path: "/home/team",
    icon: getIcon(peopleFill),
  },
  {
    title: "матчи",
    path: "/home/fixtures",
    icon: getIcon(footballIcon),
  },
  {
    title: "Прогноз",
    path: "/home/predictions",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "Юзеры",
    path: "/home/users",
    icon: getIcon(smilingFaceFill),
  },
  {
    title: "Нотификации",
    path: "/home/notifications",
    icon: getIcon(bellFill),
  },
];

export default sidebarConfig;
