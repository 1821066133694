const social = [
  {
    name: `Instagram`,
    avatarUrl: "/static/mock-images/social/instagram.png",
  },
  {
    name: `Telegram`,
    avatarUrl: "/static/mock-images/social/telegram.png",
  },
  {
    name: `Facebook`,
    avatarUrl: "/static/mock-images/social/facebook.png",
  },
  {
    name: `Web`,
    avatarUrl: "/static/mock-images/social/web.png",
  },
  {
    name: `Youtube`,
    avatarUrl: "/static/mock-images/social/youtube.png",
  },
];

export default social;
