import { useSelector, useDispatch } from "react-redux";
import { UserActions } from "../logic/user/UserRedux";
import { useState, useEffect } from "react";
import { userSelector } from "../logic/user/UserSelectors";
import { fDate } from "src/utils/formatTime";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { makeStyles, withStyles } from "@material-ui/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import moment from "moment";

import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  TextField,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { UserMoreMenu } from "../components/_dashboard/user";
import { UserListHead } from "../components/_dashboard/user";

// ----------------------------------------------------------------------

export default function Notifications() {
  const dispatch = useDispatch();
  const [ageMin, setAgeMin] = useState(1);
  const [ageMax, setAgeMax] = useState(120);
  const [title, setTitle] = useState();
  const [text, setText] = useState();
  const [sex, setSex] = useState(undefined);

  const handleAgeMinChange = (event) => {
    setAgeMin(event.target.value);
  };

  const handleAgeMaxChange = (event) => {
    setAgeMax(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleChange = (event) => {
    setSex(event.target.value);
  };

  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
  }));

  let minBirthayUnix = moment(Date.now()).subtract(ageMax, "years").unix();

  let maxBirthayUnix = moment(Date.now()).subtract(ageMin, "years").unix();

  const sendPush = () => {
    if (sex !== undefined) {
      dispatch(
        UserActions.sendNotification.request({
          birthDay: { min: minBirthayUnix, max: maxBirthayUnix },
          sex: sex,
          title: { en: title, uk: title },
          msg: { en: text, uk: text },
        })
      );
    } else {
      dispatch(
        UserActions.sendNotification.request({
          birthDay: { min: minBirthayUnix, max: maxBirthayUnix },
          title: { en: title, uk: title },
          msg: { en: text, uk: text },
        })
      );
    }
    setSex(undefined);
    setText("");
    setTitle("");
    setAgeMin(1);
    setAgeMax(120);
  };

  const classes = useStyles();

  const inputStyle2 = {
    display: "flex",
    outline: "none",
    paddingBottom: 10,
    paddingTop: 10,
  };
  const inputStyle3 = {
    display: "flex",
    outline: "none",
    paddingBottom: 10,
    paddingTop: 10,
    marginRight: 20,
    marginLeft: 20,
  };
  const inputStyle4 = {
    display: "flex",
    outline: "none",
    paddingBottom: 10,
    marginRight: 20,
    marginLeft: 20,
    marginBottom: 5,
  };
  const inputStyle5 = {
    display: "flex",
    outline: "none",
    paddingBottom: 10,
    marginRight: 20,
    marginLeft: 20,
  };

  const buttonStyle = {
    display: "flex",
    outline: "none",
    marginRight: 10,
  };

  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  return (
    <>
      <Page title="Ruh | Notifications">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}>
            <Typography variant="h4" gutterBottom>
              {"Нотификации"}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between">
              <FormControl className={classes.margin}>
                <NativeSelect
                  id="demo-customized-select-native"
                  value={sex}
                  onChange={handleChange}
                  input={<BootstrapInput />}>
                  <option aria-label="None" value="" />

                  {["m", "f", "other"].map((item, index) => {
                    return <option value={item}>{item}</option>;
                  })}
                </NativeSelect>
              </FormControl>
              <Button
                onClick={() => {
                  sendPush();
                }}
                variant="contained"
                style={buttonStyle}>
                Send
              </Button>
            </Stack>
          </Stack>

          <Card>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <TextField
                style={inputStyle3}
                value={ageMin}
                onChange={handleAgeMinChange}
                id="standard-basic"
                label="Age Min"
                placeholder={"Field is Optional"}
                variant="standard"
              />
              <TextField
                style={inputStyle2}
                value={ageMax}
                onChange={handleAgeMaxChange}
                id="standard-basic"
                label="Age Max"
                placeholder={"Field is Optional"}
                variant="standard"
              />
            </div>
            <TextField
              style={inputStyle5}
              value={title}
              onChange={handleTitleChange}
              id="standard-basic"
              label="Title"
              placeholder={"Title"}
            />
            <TextField
              style={inputStyle4}
              value={text}
              onChange={handleTextChange}
              id="standard-basic"
              label="Text"
              placeholder={"Text"}
              multiline
              rows={7}
            />
          </Card>
        </Container>
      </Page>
    </>
  );
}
