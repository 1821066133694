import {
  ActionReducerMapBuilder,
  createAction,
  createReducer,
} from "@reduxjs/toolkit";
import {
  addRestReducers,
  createRestActions,
  getDefaultRestState,
} from "./../../store/restHelper";
import { BaseErrorType, NodeRestStateType } from "./../../store/restHelper.d";
import {
  CreateEventRestActions,
  CreateSponsorRestActions,
  DeleteClubEventRestActions,
  DeleteClubMemberRestActions,
  DeleteClubRestActions,
  DeleteEventMemberRestActions,
  DeleteEventRestActions,
  DeleteSponsorRestActions,
  DeleteUserRestActions,
  EditClubRestActions,
  EditEventRestActions,
  EditFixturesRestActions,
  EditPlayersRestActions,
  EditSocialRestActions,
  EditSponsorsRestActions,
  GetClubEventsByIdRestActions,
  GetClubEventsListByIdRestActions,
  GetClubEventsRestActions,
  GetClubMembersRestActions,
  GetClubsRestActions,
  GetEventMembersRestActions,
  GetEventsRestActions,
  GetFixturesRestActions,
  GetPlayersRestActions,
  GetPredictionsRestActions,
  GetSelfRestActions,
  GetSocialRestActions,
  GetSponsorsRestActions,
  GetUsersRestActions,
  SendNotificationRestActions,
  UserLoginRestActions,
  UserLogoutRestActions,
  UserTokenRelevanceCheckRestActions,
  VoteSearchRestActions,
} from "./UserActions";
import fp from "lodash/fp";

type TokenRelevanceCheckResponse = {
  tokenExpiried: boolean;
};

export type TokenRelevanceCheckPayload = { error: BaseErrorType };
export type LoginPayload = {
  login: string;
  password: string;
  storeToken: boolean;
};

type UserLoginInfo = {
  userId?: string;
  userRole?: string;
  access_token?: string;
  token_type?: string;
  message?: string;
};

export type Player = {
  _id: number;
  _teamId: number;
};

export type Sponsors = {
  _id: string;
  name: string;
};

export type Massive = {
  name: string;
  url: string;
  image: string;
};

type GetPlayersResponse = {
  players: Player[];
  message?: string;
};

type GetPlayersPayload = {
  index: string;
};

type GetSponsorsResponse = {
  sponsors: Sponsors[];
  message?: string;
};

type GetSponsorsPayload = {
  index: string;
};

type EditPlayersResponse = {
  players: Player[];
  message?: string;
};

type EditPlayersPayload = {
  id: string;
  actualName?: string;
  ruhLink?: string;
  actualPhoto?: string;
  visible?: any;
};

type CreateSponsorResponse = {
  sponsors: Sponsors[];
  message?: string;
};

type CreateSponsorPayload = {
  name: string;
  url: string;
  priority: number;
  image: string;
};

type EditSponsorsResponse = {
  sponsors: Sponsors[];
  message?: string;
};

type EditSponsorsPayload = {
  id: string;
  sponsors: Massive[];
};

type DeleteSponsorResponse = {
  sponsors: Sponsors[];
  message?: string;
};

type DeleteSponsorPayload = {
  id: string;
};

export type Fixture = {
  _id: number;
  _teamId: number;
  ticketLink: string;
  previewLink: string;
  overviewLink: string;
  photoLink: string;
  videoLink: string;
};

type EditFixturesResponse = {
  fixture: Fixture[];
  message?: string;
};

type EditFixturesPayload = {
  id: string;
  ticketLink: string;
  previewLink: string;
  overviewLink: string;
  photoLink: string;
  videoLink: string;
};

type GetFixturesResponse = {
  fixture: Fixture[];
  message?: string;
};

type EditClubPayload = {
  id: string;
  name: string;
  address: string;
  facebook: string;
  instagram: string;
  telegram: string;
};

type EditClubResponse = {
  message?: string;
};

type GetUsersPayload = {
  index: string;
};

type GetUsersResponse = {
  message?: string;
};

export type Clubs = {
  _id: string;
  logo: string;
  name: string;
};

type GetClubsResponse = {
  clubs: Clubs[];
  message?: string;
};

type GetClubsPayload = {
  index: string;
};

export type Socials = {
  _id: string;
  url: string;
};

type GetSocialResponse = {
  socials: Socials[];
  message?: string;
};

type GetSocialPayload = {
  index: string;
};

type EditSocialResponse = {
  socials: Socials[];
  message?: string;
};

type EditSocialPayload = {
  id: string;
  url?: string;
  image?: string;
};

type DeleteClubPayload = {
  id: string;
};

export type Events = {
  locations: string;
  coords: Coords[];
};

type GetEventsResponse = {
  _id: string;
  type: string;
  time: string;
  title: string;
  events: Events[];
  message?: string;
};

type GetEventsPayload = {
  index: string;
};

export type Coords = {
  latitude: number;
  longitude: number;
};

type CreateEventResponse = {
  _id: string;
  type: string;
  time: string;
  title: string;
  events: Events[];
  message?: string;
};

type CreateEventPayload = {
  time: string;
  title: string;
  type: string;
  location: string;
  coords: Coords[];
};

type DeleteEventPayload = {
  id: string;
};

type GetSelfResponse = {
  coords?: Coords;
  name?: string;
  surname?: string;
  patronymic?: string;
  sex?: string;
  birthDay?: number;
  email?: string;
  phone?: string;
  favoritePlayer?: number;
  role: string;
  address?: Coords;
};

type EditEventResponse = {
  _id: string;
  type: string;
  time: string;
  title: string;
  events: Events[];
  message?: string;
};

type EditEventPayload = {
  id: string;
  time: string;
  title: string;
  type: string;
  location: string;
  coords: Coords[];
};

type VoteSearchResponse = {
  _id: string;
  message?: string;
};

type VoteSearchPayload = {
  fixture: number;
  sortBy: string;
  sort: number;
};

type GetPredictionsResponse = {
  _id: string;
  message?: string;
};

type GetPredictionsPayload = {
  fixtureId: string;
};

type DeleteUserPayload = {
  userId: string;
};

type DeleteUserResponse = {
  message?: string;
};

type GetClubMembersPayload = {
  id: string;
  index: string;
};

type GetClubMembersResponse = {
  message?: string;
};

type GetEventMembersPayload = {
  clubId: string;
  eventId: string;
  index: string;
};

type GetEventMembersResponse = {
  message?: string;
};

type GetClubEventsPayload = {
  index: string;
};

type GetClubEventsResponse = {
  message?: string;
};

type GetClubEventsByIdPayload = {
  clubId: string;
  eventId: string;
};

type GetClubEventsByIdResponse = {
  message?: string;
};

type DeleteClubEventPayload = {
  clubId: string;
  eventId: string;
};

type DeleteClubEventResponse = {
  message?: string;
};

type DeleteClubMemberPayload = {
  id: string;
  userId: string;
};

type DeleteClubMemberResponse = {
  message?: string;
};

type DeleteEventMemberPayload = {
  clubId: string;
  eventId: string;
  userId: string;
};

type DeleteEventMemberResponse = {
  message?: string;
};

type GetClubEventsListByIdPayload = {
  clubId: string;
  index: string;
};

type GetClubEventsListByIdResponse = {
  message?: string;
};

export type BirthDay = {
  min: number;
  max: number;
};

export type Title = {
  en: string;
  ru?: string;
  uk?: string;
};

export type Msg = {
  en: string;
  ru?: string;
  uk?: string;
};

export type SendNotificationPayload = {
  birthDay: BirthDay;
  sex: "m" | "f" | "other";
  title: Title;
  msg: Msg;
};

export type SendNotificationResponse = {
  message?: string;
};

const userTokenRelevanceCheckRestActions = createRestActions<
  typeof UserTokenRelevanceCheckRestActions,
  TokenRelevanceCheckResponse,
  TokenRelevanceCheckPayload
>(UserTokenRelevanceCheckRestActions);

const userLoginRestActions = createRestActions<
  typeof UserLoginRestActions,
  UserLoginInfo,
  LoginPayload
>(UserLoginRestActions);

const getPlayersRestActions = createRestActions<
  typeof GetPlayersRestActions,
  GetPlayersResponse,
  GetPlayersPayload
>(GetPlayersRestActions);

const getSponsorsRestActions = createRestActions<
  typeof GetSponsorsRestActions,
  GetSponsorsResponse,
  GetSponsorsPayload
>(GetSponsorsRestActions);

const editPlayersRestActions = createRestActions<
  typeof EditPlayersRestActions,
  EditPlayersResponse,
  EditPlayersPayload
>(EditPlayersRestActions);

const createSponsorRestActions = createRestActions<
  typeof CreateSponsorRestActions,
  CreateSponsorResponse,
  CreateSponsorPayload
>(CreateSponsorRestActions);

const editSponsorsRestActions = createRestActions<
  typeof EditSponsorsRestActions,
  EditSponsorsResponse,
  EditSponsorsPayload
>(EditSponsorsRestActions);

const deleteSponsorRestActions = createRestActions<
  typeof DeleteSponsorRestActions,
  DeleteSponsorResponse,
  DeleteSponsorPayload
>(DeleteSponsorRestActions);

const editFixturesRestActions = createRestActions<
  typeof EditFixturesRestActions,
  EditFixturesResponse,
  EditFixturesPayload
>(EditFixturesRestActions);

const getFixturesRestActions = createRestActions<
  typeof GetFixturesRestActions,
  GetFixturesResponse,
  void
>(GetFixturesRestActions);

const getClubsRestActions = createRestActions<
  typeof GetClubsRestActions,
  GetClubsResponse,
  GetClubsPayload
>(GetClubsRestActions);

const getSocialRestActions = createRestActions<
  typeof GetSocialRestActions,
  GetSocialResponse,
  GetSocialPayload
>(GetSocialRestActions);

const editSocialRestActions = createRestActions<
  typeof EditSocialRestActions,
  EditSocialResponse,
  EditSocialPayload
>(EditSocialRestActions);

const deleteClubRestActions = createRestActions<
  typeof DeleteClubRestActions,
  void,
  DeleteClubPayload
>(DeleteClubRestActions);

const getEventsRestActions = createRestActions<
  typeof GetEventsRestActions,
  GetEventsResponse,
  GetEventsPayload
>(GetEventsRestActions);

const createEventRestActions = createRestActions<
  typeof CreateEventRestActions,
  CreateEventResponse,
  CreateEventPayload
>(CreateEventRestActions);

const userLogoutRestActions = createRestActions<
  typeof UserLogoutRestActions,
  void
>(UserLogoutRestActions);

const getSelfRestActions = createRestActions<
  typeof GetSelfRestActions,
  GetSelfResponse
>(GetSelfRestActions);

const deleteEventRestActions = createRestActions<
  typeof DeleteEventRestActions,
  void,
  DeleteEventPayload
>(DeleteEventRestActions);

const editEventRestActions = createRestActions<
  typeof EditEventRestActions,
  EditEventResponse,
  EditEventPayload
>(EditEventRestActions);

const editClubRestActions = createRestActions<
  typeof EditClubRestActions,
  EditClubResponse,
  EditClubPayload
>(EditClubRestActions);

const voteSearchRestActions = createRestActions<
  typeof VoteSearchRestActions,
  VoteSearchResponse,
  VoteSearchPayload
>(VoteSearchRestActions);

const getUsersRestActions = createRestActions<
  typeof GetUsersRestActions,
  GetUsersResponse,
  GetUsersPayload
>(GetUsersRestActions);

const getPredictionsRestActions = createRestActions<
  typeof GetPredictionsRestActions,
  GetPredictionsResponse,
  GetPredictionsPayload
>(GetPredictionsRestActions);

const deleteUserRestActions = createRestActions<
  typeof DeleteUserRestActions,
  DeleteUserResponse,
  DeleteUserPayload
>(DeleteUserRestActions);

const getClubMembersRestActions = createRestActions<
  typeof GetClubMembersRestActions,
  GetClubMembersResponse,
  GetClubMembersPayload
>(GetClubMembersRestActions);

const getEventMembersRestActions = createRestActions<
  typeof GetEventMembersRestActions,
  GetEventMembersResponse,
  GetEventMembersPayload
>(GetEventMembersRestActions);

const getClubEventsRestActions = createRestActions<
  typeof GetClubEventsRestActions,
  GetClubEventsResponse,
  GetClubEventsPayload
>(GetClubEventsRestActions);

const getClubEventsByIdRestActions = createRestActions<
  typeof GetClubEventsByIdRestActions,
  GetClubEventsByIdResponse,
  GetClubEventsByIdPayload
>(GetClubEventsByIdRestActions);

const deleteClubEventRestActions = createRestActions<
  typeof DeleteClubEventRestActions,
  DeleteClubEventResponse,
  DeleteClubEventPayload
>(DeleteClubEventRestActions);

const deleteClubMemberRestActions = createRestActions<
  typeof DeleteClubMemberRestActions,
  DeleteClubMemberResponse,
  DeleteClubMemberPayload
>(DeleteClubMemberRestActions);

const deleteEventMemberRestActions = createRestActions<
  typeof DeleteEventMemberRestActions,
  DeleteEventMemberResponse,
  DeleteEventMemberPayload
>(DeleteEventMemberRestActions);

const getClubEventsListByIdRestActions = createRestActions<
  typeof GetClubEventsListByIdRestActions,
  GetClubEventsListByIdResponse,
  GetClubEventsListByIdPayload
>(GetClubEventsListByIdRestActions);

const sendNotificationRestActions = createRestActions<
  typeof SendNotificationRestActions,
  SendNotificationResponse,
  SendNotificationPayload
>(SendNotificationRestActions);

const UserActions = {
  changeStep: createAction<UserStep>("user/changeStep"),
  login: userLoginRestActions,
  getPlayers: getPlayersRestActions,
  getClubEventsListById: getClubEventsListByIdRestActions,
  deleteClubEvent: deleteClubEventRestActions,
  deleteSponsor: deleteSponsorRestActions,
  deleteClubMember: deleteClubMemberRestActions,
  editPlayers: editPlayersRestActions,
  getSponsors: getSponsorsRestActions,
  getClubEvents: getClubEventsRestActions,
  getClubEventsById: getClubEventsByIdRestActions,
  getSocial: getSocialRestActions,
  getEventMembers: getEventMembersRestActions,
  deleteEventMember: deleteEventMemberRestActions,
  editSponsors: editSponsorsRestActions,
  editFixtures: editFixturesRestActions,
  editClub: editClubRestActions,
  editSocial: editSocialRestActions,
  sendNotification: sendNotificationRestActions,
  voteSearch: voteSearchRestActions,
  deleteUser: deleteUserRestActions,
  getFixtures: getFixturesRestActions,
  getClubMembers: getClubMembersRestActions,
  getClubs: getClubsRestActions,
  getUsers: getUsersRestActions,
  getPredictions: getPredictionsRestActions,
  getEvents: getEventsRestActions,
  deleteEvent: deleteEventRestActions,
  editEvent: editEventRestActions,
  deleteClub: deleteClubRestActions,
  createSponsor: createSponsorRestActions,
  createEvent: createEventRestActions,
  tokenRelevanceCheck: userTokenRelevanceCheckRestActions,
  logout: userLogoutRestActions,
  getSelf: getSelfRestActions,
  storeTokenToRedux: createAction<string>("user/storeTokenToRedux"),
  storeUserIdToRedux: createAction<string | undefined>("user/storeUserIdRedux"),
  storeUserRoleToRedux: createAction<string | undefined>(
    "user/storeUserRoleRedux"
  ),
  setMyProfile: createAction<GetSelfResponse | undefined>('user/setMyProfile'),
};

type UserRestNodes =
  | "login"
  | "logout"
  | "tokenRelevanceCheck"
  | "getPlayers"
  | "getSponsors"
  | "editPlayers"
  | "createSponsor"
  | "editSponsors"
  | "deleteSponsor"
  | "editFixtures"
  | "getFixtures"
  | "getClubs"
  | "getSocial"
  | "editSocial"
  | "deleteClub"
  | "getEvents"
  | "createEvent"
  | "deleteEvent"
  | "editEvent"
  | "voteSearch"
  | "getPredictions"
  | "editClub"
  | "getUsers"
  | "deleteUser"
  | "getClubMembers"
  | "getEventMembers"
  | "getClubEvents"
  | "getClubEventsById"
  | "deleteClubEvent"
  | "deleteClubMember"
  | "deleteEventMember"
  | "getClubEventsListById"
  | "sendNotification"
  | "getSelf";

type UserStep = "login" | "uploadExcel" | "forgotPassword" | "homeView";

type UserStore = {
  step: UserStep;
  userToken: string;
  userId: string | undefined;
  userRole: string | undefined;
  profile: GetSelfResponse | undefined;
};

const initUserStore: UserStore = {
  step: "login",
  userToken: ``,
  userId: undefined,
  userRole: undefined,
  profile: undefined
};

const initialRestState = {
  login: getDefaultRestState<UserLoginInfo>(),
  tokenRelevanceCheck: getDefaultRestState(),
  logout: getDefaultRestState(),
  getPlayers: getDefaultRestState(),
  getClubEventsListById: getDefaultRestState(),
  getClubEvents: getDefaultRestState(),
  getClubEventsById: getDefaultRestState(),
  getEventMembers: getDefaultRestState(),
  getSponsors: getDefaultRestState(),
  sendNotification: getDefaultRestState(),
  deleteClubEvent: getDefaultRestState(),
  deleteClubMember: getDefaultRestState(),
  deleteEventMember: getDefaultRestState(),
  deleteUser: getDefaultRestState(),
  getEvents: getDefaultRestState(),
  getUsers: getDefaultRestState(),
  editClub: getDefaultRestState(),
  getClubMembers: getDefaultRestState(),
  editSponsors: getDefaultRestState(),
  editPlayers: getDefaultRestState(),
  editEvent: getDefaultRestState(),
  getPredictions: getDefaultRestState(),
  createSponsor: getDefaultRestState(),
  deleteSponsor: getDefaultRestState(),
  deleteEvent: getDefaultRestState(),
  editFixtures: getDefaultRestState(),
  getFixtures: getDefaultRestState(),
  getClubs: getDefaultRestState(),
  voteSearch: getDefaultRestState(),
  getSocial: getDefaultRestState(),
  editSocial: getDefaultRestState(),
  deleteClub: getDefaultRestState(),
  createEvent: getDefaultRestState(),
  getSelf: getDefaultRestState()
};

type UserState = NodeRestStateType<
  UserRestNodes,
  UserStore & typeof initialRestState
>;

type Builder = ActionReducerMapBuilder<UserState>;

const userReducer = createReducer(
  { ...initUserStore, ...initialRestState },
  (builder) =>
    (
      fp.flow([
        addRestReducers(userLoginRestActions, "login"),
        addRestReducers(userLogoutRestActions, "logout"),
        addRestReducers(getPlayersRestActions, "getPlayers"),
        addRestReducers(editPlayersRestActions, "editPlayers"),
        addRestReducers(createSponsorRestActions, "createSponsor"),
        addRestReducers(editSponsorsRestActions, "editSponsors"),
        addRestReducers(getSponsorsRestActions, "getSponsors"),
        addRestReducers(getClubEventsRestActions, "getClubEvents"),
        addRestReducers(getClubEventsByIdRestActions, "getClubEventsById"),
        addRestReducers(deleteSponsorRestActions, "deleteSponsor"),
        addRestReducers(editFixturesRestActions, "editFixtures"),
        addRestReducers(getFixturesRestActions, "getFixtures"),
        addRestReducers(getClubsRestActions, "getClubs"),
        addRestReducers(deleteUserRestActions, "deleteUser"),
        addRestReducers(deleteClubMemberRestActions, "deleteClubMember"),
        addRestReducers(getSocialRestActions, "getSocial"),
        addRestReducers(editSocialRestActions, "editSocial"),
        addRestReducers(deleteClubRestActions, "deleteClub"),
        addRestReducers(createEventRestActions, "createEvent"),
        addRestReducers(getEventsRestActions, "getEvents"),
        addRestReducers(deleteEventMemberRestActions, "deleteEventMember"),
        addRestReducers(deleteEventRestActions, "deleteEvent"),
        addRestReducers(editEventRestActions, "editEvent"),
        addRestReducers(voteSearchRestActions, "voteSearch"),
        addRestReducers(sendNotificationRestActions, "sendNotification"),
        addRestReducers(getUsersRestActions, "getUsers"),
        addRestReducers(getPredictionsRestActions, "getPredictions"),
        addRestReducers(editClubRestActions, "editClub"),
        addRestReducers(deleteClubEventRestActions, "deleteClubEvent"),
        addRestReducers(getClubMembersRestActions, "getClubMembers"),
        addRestReducers(getEventMembersRestActions, "getEventMembers"),
        addRestReducers(getSelfRestActions, "getSelf"),
        addRestReducers(
          getClubEventsListByIdRestActions,
          "getClubEventsListById"
        ),

        addRestReducers(
          userTokenRelevanceCheckRestActions,
          "tokenRelevanceCheck"
        ),
      ])(builder) as Builder
    )
      .addCase(UserActions.storeTokenToRedux, (state, action) => {
        state.userToken = action.payload;
      })
      .addCase(UserActions.storeUserIdToRedux, (state, action) => {
        state.userId = action.payload;
      })
      .addCase(UserActions.storeUserRoleToRedux, (state, action) => {
        state.userRole = action.payload;
      })
      .addCase(UserActions.changeStep, (state, action) => {
        state.step = action.payload;
      })
      .addCase(UserActions.setMyProfile, (state, action) => {
        state.profile = action.payload;
      })
);

export { userReducer, UserActions };
