import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { styled } from "@material-ui/core/styles";
import { LoadingButton } from "@material-ui/lab";
import { useSelector, useDispatch } from "react-redux";
import { userSelector } from "./../../../logic/user/UserSelectors";
import { UserActions } from "./../../../logic/user/UserRedux";

// import { Button } from "./../../Button/Button";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Alert,
} from "@material-ui/core";
import { MaterialInput } from "./../../MaterialInput/MaterialInput";
import {
  validateLogin,
  validatePassword,
} from "./../../../helpers/ValidationHelper";
import palette from "./../../../theme/palette";
import { Stack } from "@material-ui/core";

export default function LoginForm(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    login: { fetching: loginFetching, error: loginError },
  } = useSelector(userSelector);

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [hideErrors, setHideErrors] = useState(true);

  const handleChange = (event) => setLogin(event.target.value);
  const handleChange1 = (event) => setPassword(event.target.value);

  const handleSignIn = () => {
    if (validateLogin(login) && validatePassword(password)) {
      dispatch(
        UserActions.login.request({
          login,
          password,
        })
      );
    } else {
      setHideErrors(false);
    }
  };

  useEffect(() => {
    if (loginError?.code !== undefined) {
      props.setNotApprove(loginError.code);
  
    } else {
      // console.log("2");
    }
  }, [loginError]);

  return (
    <Stack spacing={3} style={{ alignItems: "center" }}>
      <MaterialInput
        value={login}
        handleChange={handleChange}
        validation={validateLogin(login) || hideErrors}
        label={"Enter your login"}
        errorText={"Enter the correct login"}
      />
      <MaterialInput
        value={password}
        password
        handleChange={handleChange1}
        validation={validatePassword(password) || hideErrors}
        label={"Enter your password"}
        errorText={"Must be valid"}
      />
      {/* <Button
        background={palette.common.black}
        onClick={handleSignIn}
        title={"Log In"}
        fetching={loginFetching}
      /> */}
      <Button
        onClick={handleSignIn}
        fullWidth
        href=""
        target="_blank"
        variant="contained">
        Login
      </Button>
    </Stack>
  );
}
