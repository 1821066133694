import { useSelector, useDispatch } from "react-redux";
import { UserActions } from "../logic/user/UserRedux";
import { useState, useEffect } from "react";
import { userSelector } from "../logic/user/UserSelectors";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TextField,
  Modal,
} from "@material-ui/core";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { UserMoreMenu2 } from "../components/_dashboard/user";
import SOCIAL from "../_mocks_/social";
import { UserListHead } from "../components/_dashboard/user";

// ----------------------------------------------------------------------

export default function Social() {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [link, setLink] = useState("");
  const [image, setImage] = useState("");
  const [linkId, setLinkId] = useState();
  const [userData, setUserData] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleLinkChange = (event) => {
    setLink(event.target.value);
  };

  const handleImageChange = (event) => {
    setImage(event.target.value);
  };
  const dispatch = useDispatch();
  const {
    getSocial: { data: getSocialData, fetching: getSocialFetching },
  } = useSelector(userSelector);
  useEffect(() => {
    dispatch(UserActions.getSocial.request({ index: 0 }));
  }, []);

  useEffect(() => {
    setUserData(getSocialData);
  }, [getSocialData]);

  const editSocial = (id, url, image) => {
    if (url !== "") {
      dispatch(
        UserActions.editSocial.request({ id: id, url: url, image: image })
      );
    }
    setLink("");
    setImage("");
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - SOCIAL.length) : 0;

  const inputStyle2 = {
    display: "flex",
    outline: "none",
    paddingBottom: 10,
    paddingTop: 10,
  };

  const divStyle = {
    display: "flex",
    justifyContent: "center",
    outline: "none",
    flexDirection: "column",
    padding: 40,
  };
  const divStyle2 = {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    outline: "none",
  };

  const TABLE_HEAD = [
    { id: "Network", label: "Network", alignRight: false },
    { id: "Link", label: "Link", alignRight: false },
  ];

  return (
    <>
      <Page title="Ruh | Links">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}>
            <Typography variant="h4" gutterBottom>
              Ссылки
            </Typography>
          </Stack>

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead padding={"none"} headLabel={TABLE_HEAD} />
                  {userData !== undefined ? (
                    <TableBody>
                      {userData.socials

                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const { id, name, url, image } = row;
                          const isItemSelected = selected.indexOf(name) !== -1;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}>
                              <TableCell padding="checkbox"></TableCell>

                              <TableCell
                                component="th"
                                scope="row"
                                padding="none">
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}>
                                  <Avatar alt={name} src={image} />
                                  <Typography variant="subtitle2" noWrap>
                                    {name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">{url}</TableCell>
                              <TableCell align="right">
                                <UserMoreMenu2
                                  onDelete={() => {}}
                                  onEdit={() => {
                                    setIsOpenModal(true);
                                    setLinkId(index);
                                    setLink(getSocialData.socials[index].url);
                                    setImage(
                                      getSocialData.socials[index].image
                                    );
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Container>
      </Page>
      <Modal
        style={divStyle2}
        open={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <Card style={divStyle} elevation={3}>
          <TextField
            value={link}
            onChange={handleLinkChange}
            style={inputStyle2}
            id="standard-basic"
            label="Social Link"
          />
          <TextField
            value={image}
            onChange={handleImageChange}
            style={inputStyle2}
            id="standard-basic"
            label="Image Link"
          />
          <Button
            onClick={() => {
              editSocial(getSocialData.socials[linkId]._id, link, image);
              setIsOpenModal(false);
            }}
            fullWidth
            target="_blank"
            variant="contained">
            Save
          </Button>
        </Card>
      </Modal>
    </>
  );
}
