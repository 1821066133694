import apisaucePlugin from "reactotron-apisauce";
import Reactotron from "reactotron-react-js";
import { reactotronRedux } from "reactotron-redux";
import sagaPlugin from "reactotron-redux-saga";

export const reactotron = Reactotron
  // .setAsyncStorageHandler(AsyncStorage) // AsyncStorage would either come from `react-native` or `@react-native-community/async-storage` depending on where you get it from
  .configure({
    host: "192.168.0.11",
    name: "ruh_admin_web",
  })
  .use(sagaPlugin({}))
  .use(reactotronRedux())
  .use(
    apisaucePlugin({
      // ignoreContentTypes: /^(image)\/.*$/i   // <--- a way to skip printing the body of some requests (default is any image)
    })
  ) // <-- here we go!!!
  // .connect(); // let's connect!
