import { useSelector, useDispatch } from "react-redux";
import { UserActions } from "../logic/user/UserRedux";
import { useState, useEffect } from "react";
import { userSelector } from "../logic/user/UserSelectors";
import { fDate } from "src/utils/formatTime";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  TextField,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { UserMoreMenu2 } from "../components/_dashboard/user";
import { UserListHead } from "../components/_dashboard/user";

// ----------------------------------------------------------------------

export default function Fixtures() {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [ticketLink, setTicketLink] = useState("");
  const [previewLink, setPreviewLink] = useState("");
  const [overviewLink, setOverviewLink] = useState("");
  const [photoLink, setPhotoLink] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [requestId, setRequestId] = useState("");
  const [userData, setUserData] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [state, setState] = useState({
    checkedA: true,
    checkedB: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleState = (col) => {
    if (col) {
      return userData.past;
    } else {
      return userData.future;
    }
  };

  const handleTimeConvert = (time) => {
    return fDate(time);
  };
  const handleTicketLinkChange = (event) => {
    setTicketLink(event.target.value);
  };
  const handlePreviewLinkChange = (event) => {
    setPreviewLink(event.target.value);
  };
  const handleOverviewLinkChange = (event) => {
    setOverviewLink(event.target.value);
  };
  const handlePhotoLinkChange = (event) => {
    setPhotoLink(event.target.value);
  };
  const handleVideoLinkChange = (event) => {
    setVideoLink(event.target.value);
  };
  const {
    getFixtures: { data: getFixturesData, fetching: getFixturesFetching },
    editFixtures: { data: editFixturesData, fetching: editFixturesFetching },
  } = useSelector(userSelector);

  useEffect(() => {
    dispatch(UserActions.getFixtures.request({ index: 0 }));
  }, []);

  useEffect(() => {
    setUserData(getFixturesData);
  }, [getFixturesData]);

  const editFixtures = (
    requestId,
    ticketLink,
    previewLink,
    overviewLink,
    photoLink,
    videoLink
  ) => {
    const i = requestId;
    var ide;
    var id;
    if (state.checkedA) {
      ide = userData.past[i]._id;
      id = userData.past[i];
    } else {
      ide = userData.future[i]._id;
      id = userData.future[i];
    }
    dispatch(
      UserActions.editFixtures.request({
        id: ide,
        ticketLink: ticketLink ? ticketLink : id.ticketLink,
        previewLink: previewLink ? previewLink : id.previewLink,
        overviewLink: overviewLink ? overviewLink : id.overviewLink,
        photoLink: photoLink ? photoLink : id.photoLink,
        videoLink: videoLink ? videoLink : id.videoLink,
      })
    );
    setTicketLink("");
    setPreviewLink("");
    setOverviewLink("");
    setPhotoLink("");
    setVideoLink("");
  };

  const divStyle = {
    display: "flex",
    justifyContent: "center",
    outline: "none",
    flexDirection: "column",
    padding: 40,
  };
  const divStyle2 = {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    outline: "none",
  };

  const inputStyle = {
    display: "flex",
    outline: "none",
  };

  const inputStyle2 = {
    display: "flex",
    outline: "none",
    paddingBottom: 10,
    paddingTop: 10,
  };

  const TABLE_HEAD = [
    { id: "Game", label: "Game", alignRight: false },
    { id: "Date", label: "Date", alignRight: false },
    { id: "OverviewLink", label: "Overview Link", alignRight: false },
    { id: "PhotoLink", label: "Photo Link", alignRight: false },
    { id: "PreviewLink", label: "Preview Link", alignRight: false },
    { id: "TicketLink", label: "Ticket Link", alignRight: false },
    { id: "VideoLink", label: "Video Link", alignRight: false },
  ];

  return (
    <>
      <Page title="Ruh | Fixtures">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}>
            <Typography variant="h4" gutterBottom>
              {state.checkedA ? "Прошлые Матчи" : "Будущие Матчи"}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between">
              <Switch
                checked={state.checkedA}
                onChange={handleChange}
                name="checkedA"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </Stack>
          </Stack>

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} />
                  {userData !== undefined ? (
                    <TableBody>
                      {handleState(state.checkedA).map((row, index) => {
                        const {
                          id,
                          name,
                          position,
                          actualPhoto,
                          ruhLink,
                          fixture,
                          teams,
                          overviewLink,
                          photoLink,
                          previewLink,
                          ticketLink,
                          videoLink,
                        } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}>
                            <>
                              <TableCell align="left"></TableCell>
                              <TableCell>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}>
                                  <Avatar
                                    alt={name}
                                    src={
                                      "https://cdn-icons-png.flaticon.com/512/942/942037.png"
                                    }
                                  />
                                  <Typography variant="subtitle2" noWrap>
                                    {teams.home.name + " vs " + teams.away.name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                {handleTimeConvert(fixture.date)}
                              </TableCell>
                              <TableCell align="left">{overviewLink}</TableCell>
                              <TableCell align="left">{photoLink}</TableCell>
                              <TableCell align="left">{previewLink}</TableCell>
                              <TableCell align="left">{ticketLink}</TableCell>
                              <TableCell align="left">{videoLink}</TableCell>

                              <TableCell align="right">
                                <UserMoreMenu2
                                  onEdit={() => {
                                    setIsOpenModal(true);
                                    setRequestId(index);
                                  }}
                                />
                              </TableCell>
                            </>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Container>
      </Page>
      <Modal
        style={divStyle2}
        open={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <Card style={divStyle} elevation={3}>
          <TextField
            style={inputStyle2}
            value={ticketLink}
            onChange={handleTicketLinkChange}
            id="standard-basic"
            label="Ticket Link"
          />
          <TextField
            style={inputStyle}
            value={previewLink}
            onChange={handlePreviewLinkChange}
            id="standard-basic"
            label="Preview Link"
          />
          <TextField
            value={overviewLink}
            onChange={handleOverviewLinkChange}
            style={inputStyle2}
            id="standard-basic"
            label="Overview Link"
          />
          <TextField
            value={photoLink}
            onChange={handlePhotoLinkChange}
            style={inputStyle2}
            id="standard-basic"
            label="Photo Link"
          />
          <TextField
            value={videoLink}
            onChange={handleVideoLinkChange}
            style={inputStyle2}
            id="standard-basic"
            label="Video Link	"
          />
          <Button
            onClick={() => {
              editFixtures(
                requestId,
                ticketLink,
                previewLink,
                overviewLink,
                photoLink,
                videoLink
              );
              setIsOpenModal(false);
            }}
            fullWidth
            target="_blank"
            variant="contained">
            Save
          </Button>
        </Card>
      </Modal>
    </>
  );
}
