import { useSelector, useDispatch } from "react-redux";
import { UserActions } from "../logic/user/UserRedux";
import { useState, useEffect } from "react";
import { userSelector } from "../logic/user/UserSelectors";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  TextField,
  Switch,
  FormControlLabel,
  FormGroup,
  styled,
} from "@material-ui/core";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { UserMoreMenu2 } from "../components/_dashboard/user";
import { UserListHead } from "../components/_dashboard/user";

// ----------------------------------------------------------------------

export default function Team() {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [imageLink, setImageLink] = useState("");
  const [actualName, setActualName] = useState("");
  const [ruhLink, setRuhLink] = useState("");
  const [requestId, setRequestId] = useState();
  const [userData, setUserData] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [userInput, setUserInput] = useState("");

  const handleRuhLinkChange = (event) => {
    setRuhLink(event.target.value);
  };
  const handleActualNameChange = (event) => {
    setActualName(event.target.value);
  };
  const handleImageLinkChange = (event) => {
    setImageLink(event.target.value);
  };

  const handleSearchChange = (event) => {
    setUserInput(event.target.value);
  };
  const {
    getPlayers: { data: getPlayersData, fetching: getPlayersFetching },
  } = useSelector(userSelector);

  useEffect(() => {
    dispatch(UserActions.getPlayers.request({ index: 0 }));
  }, []);

  useEffect(() => {
    setUserData(getPlayersData);
  }, [getPlayersData]);

  const editPlayers = (requestId, actualName, imageLink, ruhLink) => {
    const i = requestId;
    const ide = getPlayersData.players[i]._id;
    dispatch(
      UserActions.editPlayers.request({
        id: ide,
        actualName: actualName
          ? actualName
          : getPlayersData.players[i].actualName,
        actualPhoto: imageLink
          ? imageLink
          : getPlayersData.players[i].actualPhoto,
        ruhLink: ruhLink ? ruhLink : getPlayersData.players[i].ruhLink,
      })
    );
    setImageLink();
    setActualName();
    setRuhLink();
  };

  const editPlayersVisibility = (requestId, visible) => {
    const i = requestId;
    const ide = getPlayersData.players[i]._id;
    dispatch(
      UserActions.editPlayers.request({
        id: ide,
        visible: visible,
      })
    );
  };

  const divStyle = {
    display: "flex",
    justifyContent: "center",
    outline: "none",
    flexDirection: "column",
    padding: 40,
  };
  const divStyle2 = {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    outline: "none",
  };

  const inputStyle = {
    display: "flex",
    outline: "none",
  };

  const inputStyle2 = {
    display: "flex",
    outline: "none",
    paddingBottom: 10,
    paddingTop: 10,
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const TABLE_HEAD = [
    { id: "Player", label: "Player", alignRight: false },
    { id: "PlayerNumber", label: "Number", alignRight: false },
    { id: "Role", label: "Role", alignRight: false },
    { id: "Link", label: "Link", alignRight: false },
    { id: "Visible", label: "Visible", alignRight: false },
  ];

  return (
    <>
      <Page title="Ruh | Team">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}>
            <Typography variant="h4" gutterBottom>
              Состав
            </Typography>
          </Stack>

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} />
                  {userData !== undefined ? (
                    <TableBody>
                      {userData.players.map((row, index) => {
                        const {
                          id,
                          name,
                          position,
                          number,
                          actualPhoto,
                          ruhLink,
                          actualName,
                          visible,
                        } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}>
                            <>
                              <TableCell align="left"></TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none">
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}>
                                  <Avatar
                                    alt={name}
                                    src={
                                      actualPhoto
                                        ? actualPhoto
                                        : "https://www.iconbunny.com/icons/media/catalog/product/3/5/352.9-football-player-icon-iconbunny.jpg"
                                    }
                                  />
                                  <Typography variant="subtitle2" noWrap>
                                    {actualName ? actualName : name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">{number}</TableCell>
                              <TableCell align="left">{position}</TableCell>
                              <TableCell align="left">{ruhLink}</TableCell>
                              <TableCell align="left">
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <IOSSwitch
                                        sx={{ m: 1 }}
                                        checked={visible}
                                        onChange={() => {
                                          editPlayersVisibility(
                                            index,
                                            !visible
                                          );
                                        }}
                                        defaultChecked
                                      />
                                    }
                                    label=""
                                  />
                                </FormGroup>
                              </TableCell>
                              <TableCell align="right">
                                <UserMoreMenu2
                                  onEdit={() => {
                                    setIsOpenModal(true);
                                    setRequestId(index);
                                  }}
                                />
                              </TableCell>
                            </>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Container>
      </Page>
      <Modal
        style={divStyle2}
        open={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <Card style={divStyle} elevation={3}>
          <TextField
            style={inputStyle2}
            value={imageLink}
            onChange={handleImageLinkChange}
            id="standard-basic"
            label="Actual Image Link"
          />
          <TextField
            style={inputStyle}
            value={actualName}
            onChange={handleActualNameChange}
            id="standard-basic"
            label="Actual Name"
          />
          <TextField
            value={ruhLink}
            onChange={handleRuhLinkChange}
            style={inputStyle2}
            id="standard-basic"
            label="Ruh Link"
          />
          <Button
            onClick={() => {
              editPlayers(requestId, actualName, imageLink, ruhLink);
              setIsOpenModal(false);
            }}
            fullWidth
            target="_blank"
            variant="contained">
            Save
          </Button>
        </Card>
      </Modal>
    </>
  );
}
