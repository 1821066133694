import { styled } from "@material-ui/core/styles";
import { Card, Stack, Link, Container, Typography } from "@material-ui/core";
import Page from "../components/Page";
import { LoginForm } from "../components/authentication/login";
import { useState, useEffect } from "react";
import { Button, Alert } from "@material-ui/core";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const [notApprove, setNotApprove] = useState(0);

  return (
    <RootStyle title="Login | RUH">
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5, alignItems: "center" }}>
            {notApprove === 404 && (
              <>
                <Alert
                  severity="error"
                  onClose={() => {
                    setNotApprove("");
                  }}>
                  Wrong Email or Password!
                </Alert>
              </>
            )}
            <Typography style={{ marginTop: 20 }} variant="h3" gutterBottom>
              LOGIN
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Enter your details below.
            </Typography>
          </Stack>
          <LoginForm notApprove={notApprove} setNotApprove={setNotApprove} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
