import { create } from "apisauce";
import { getConfig } from "./../config/Config";
import {
  FAQ_URL,
  GetPlayersRequest,
  GetPlayersResponse,
  LoginRequest,
  LoginResponse,
  LogoutResponse,
  GetSponsorsRequest,
  GetSponsorsResponse,
  EditPlayersRequest,
  EditPlayersResponse,
  CreateSponsorRequest,
  CreateSponsorResponse,
  EditSponsorsRequest,
  EditSponsorsResponse,
  DeleteSponsorRequest,
  DeleteSponsorResponse,
  EditFixturesRequest,
  EditFixturesResponse,
  GetFixturesResponse,
  GetClubsRequest,
  GetClubsResponse,
  GetSocialRequest,
  GetSocialResponse,
  EditSocialRequest,
  EditSocialResponse,
  DeleteClubRequest,
  GetEventsResponse,
  GetEventsRequest,
  CreateEventRequest,
  CreateEventResponse,
  DeleteEventRequest,
  EditEventRequest,
  EditEventResponse,
  VoteSearchRequest,
  VoteSearchResponse,
  GetPredictionsResponse,
  GetPredictionsRequest,
  EditClubRequest,
  EditClubResponse,
  GetUsersRequest,
  GetUsersResponse,
  DeleteUserRequest,
  DeleteUserResponse,
  GetClubMembersRequest,
  GetClubMembersResponse,
  GetEventMembersRequest,
  GetEventMembersResponse,
  GetClubEventsRequest,
  GetClubEventsResponse,
  GetClubEventsByIdResponse,
  GetClubEventsByIdRequest,
  DeleteClubEventRequest,
  DeleteClubEventResponse,
  DeleteClubMemberResponse,
  DeleteClubMemberRequest,
  DeleteEventMemberRequest,
  DeleteEventMemberResponse,
  GetClubEventsListByIdRequest,
  GetClubEventsListByIdResponse,
  SendNotificationResponse,
  SendNotificationRequest,
  GetSelfResponse,
} from "./../types/api";
import { errorResponseTransform } from "./errorResponseTransform";

const config = getConfig();

const sauce = create({
  baseURL: config.baseURL,
  headers: { Accept: "application/json" },
});

sauce.addResponseTransform(errorResponseTransform);

const api = {
  sauce,
  loginPost: function (params: LoginRequest) {
    return sauce.post<LoginResponse>("/auth/login", params);
  },
  logoutGet: function (token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.get<LogoutResponse>("/auth/logout");
  },
  getPlayersGet: function (params: GetPlayersRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.get<GetPlayersResponse>(
      `/players/list/${params.index}`
    );
  },
  getSponsorsGet: function (params: GetSponsorsRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.get<GetSponsorsResponse>(
      `/sponsors/list/${params.index}`
    );
  },

  editPlayersPost: function (params: EditPlayersRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.post<EditPlayersResponse>(
      `/players/edit/${params.id}`,
      params
    );
  },
  createSponsorPost: function (params: CreateSponsorRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.post<CreateSponsorResponse>(
      `/sponsors/create`,
      params
    );
  },
  editSponsorsPost: function (params: EditSponsorsRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.post<EditSponsorsResponse>(
      `/sponsors/edit/${params.id}`,
      params
    );
  },
  deleteSponsorDelete: function (params: DeleteSponsorRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.delete<DeleteSponsorResponse>(
      `/sponsors/${params.id}`
    );
  },
  editFixturesPost: function (params: EditFixturesRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.post<EditFixturesResponse>(
      `/fixtures/edit/${params.id}`,
      params
    );
  },
  getFixturesGet: function (token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.get<GetFixturesResponse>(`/fixtures/table`);
  },
  getClubsGet: function (params: GetClubsRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.get<GetClubsResponse>(`/clubs/list/${params.index}`);
  },
  getSocialGet: function (params: GetSocialRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.get<GetSocialResponse>(
      `/socials/list/${params.index}`
    );
  },

  editSocialPost: function (params: EditSocialRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.post<EditSocialResponse>(
      `/socials/edit/${params.id}`,
      params
    );
  },
  deleteClubDelete: function (params: DeleteClubRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.delete<any>(`/clubs/${params.id}`);
  },
  getEventsGet: function (params: GetEventsRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.get<GetEventsResponse>(
      `/events/matchday/list/${params.index}`
    );
  },
  createEventPost: function (params: CreateEventRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.post<CreateEventResponse>(`/events/create`, params);
  },
  deleteEventDelete: function (params: DeleteEventRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.delete<any>(`/events/${params.id}`);
  },
  editEventPost: function (params: EditEventRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.post<EditEventResponse>(
      `/events/edit/${params.id}`,
      params
    );
  },
  editClubPost: function (params: EditClubRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.post<EditClubResponse>(
      `/clubs/edit/${params.id}`,
      params
    );
  },
  voteSearchPost: function (params: VoteSearchRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.post<VoteSearchResponse>(`/votes/search/0`, params);
  },
  getPredictionsGet: function (params: GetPredictionsRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.get<GetPredictionsResponse>(
      `/predictions/fixture/${params.fixtureId}`
    );
  },
  getUsersGet: function (params: GetUsersRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.get<GetUsersResponse>(`/users/list/${params.index}`);
  },
  deleteUserDelete: function (params: DeleteUserRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.delete<DeleteUserResponse>(
      `/users/${params.userId}`
    );
  },
  getClubMembersGet: function (params: GetClubMembersRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.get<GetClubMembersResponse>(
      `/clubs/${params.id}/members/${params.index}`
    );
  },
  getEventMembersGet: function (params: GetEventMembersRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.get<GetEventMembersResponse>(
      `/clubs/${params.clubId}/events/${params.eventId}/members/list/${params.index}`
    );
  },
  getClubEventsListByIdGet: function (
    params: GetClubEventsListByIdRequest,
    token: string
  ) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.get<GetClubEventsListByIdResponse>(
      `/clubs/${params.clubId}/events/list/${params.index}`
    );
  },
  getClubEventsGet: function (params: GetClubEventsRequest, token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.get<GetClubEventsResponse>(
      `/events/club/list/${params.index}`
    );
  },
  deleteClubMemberDelete: function (
    params: DeleteClubMemberRequest,
    token: string
  ) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.delete<DeleteClubMemberResponse>(
      `/clubs/${params.id}/members/${params.userId}`
    );
  },
  getClubEventsByIdGet: function (
    params: GetClubEventsByIdRequest,
    token: string
  ) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.get<GetClubEventsByIdResponse>(
      `/clubs/${params.clubId}/events/one/${params.eventId}`
    );
  },
  deleteClubEventDelete: function (
    params: DeleteClubEventRequest,
    token: string
  ) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.delete<DeleteClubEventResponse>(
      `/clubs/${params.clubId}/events/${params.eventId}`
    );
  },
  deleteEventMemberDelete: function (
    params: DeleteEventMemberRequest,
    token: string
  ) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.delete<DeleteEventMemberResponse>(
      `/clubs/${params.clubId}/events/${params.eventId}/members/${params.userId}`
    );
  },
  sendNotificationPost: function (
    params: SendNotificationRequest,
    token: string
  ) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.post<SendNotificationResponse>(
      `/users/notification`,
      params
    );
  },
  getSelfGet: function (token: string) {
    const sauceAuthorized = create({
      baseURL: config.baseURL,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    return sauceAuthorized.get<GetSelfResponse>(
      `/profile/`
    );
  },
};

export { api };
