import { CustomCommand } from "reactotron-core-client";
import { UserActions } from "./../logic/user/UserRedux";
import { store } from "./../store/store";

const Logout: CustomCommand = {
  command: "Logout",
  handler: () => {
    store.dispatch(UserActions.logout.request());
  },
};

const Login: CustomCommand = {
  command: "Login",
  description: "Вход в аккаунт",
  handler: () => {
    store.dispatch(
      UserActions.login.request({
        login: "admin11",
        password: "jeg20PnMCCpEtDYR",
        storeToken: true,
      })
    );
  },
};

const Players: CustomCommand = {
  command: "GetPlayers",
  description: "get players list",
  handler: () => {
    store.dispatch(
      UserActions.getPlayers.request({
        index: "1",
      })
    );
  },
};

const Sponsors: CustomCommand = {
  command: "GetSponsors",
  description: "get sponsors list",
  handler: () => {
    store.dispatch(
      UserActions.getSponsors.request({
        index: "0",
      })
    );
  },
};

const EditPlayers: CustomCommand = {
  command: "EditPlayers",
  description: "edit player",
  handler: () => {
    store.dispatch(
      UserActions.editPlayers.request({
        id: "63374",
        actualName: "John Doe",
      })
    );
  },
};

const CreateSponsor: CustomCommand = {
  command: "CreateSponsor",
  description: "create sponsor",
  handler: () => {
    store.dispatch(
      UserActions.createSponsor.request({
        name: "dfdsgfsdgdsfg dsgdfg",
        url: "https://example.com/home/app",
        priority: 1,
        image: "https://media.api-sports.io/football/players/63842.png",
      })
    );
  },
};

const EditSponsors: CustomCommand = {
  command: "EditSponsors",
  description: "edit sponsor",
  handler: () => {
    store.dispatch(
      UserActions.editSponsors.request({
        id: "613ca412d7dcca43fab9d1e2",
        sponsors: [
          {
            name: "John Doe",
            url: "https://example.com/home/app",
            image: "https://media.api-sports.io/football/players/63842.png",
          },
          {
            name: "dfdsgfsdgdsfg dsgdfg",
            url: "https://example.com/home/app",
            image: "https://media.api-sports.io/football/players/63842.png",
          },
        ],
      })
    );
  },
};

const DeleteSponsor: CustomCommand = {
  command: "DeleteSponsor",
  description: "delete sponsor",
  handler: () => {
    store.dispatch(
      UserActions.deleteSponsor.request({
        id: "613b55cfc2c87396525e604f",
      })
    );
  },
};

const EditFixtures: CustomCommand = {
  command: "EditFixtures",
  description: "edit fixtures",
  handler: () => {
    store.dispatch(
      UserActions.editFixtures.request({
        id: "613b4ff4b8380e92aedc110e",
        ticketLink: "dfdfdfdf",
        previewLink: "dfdfsfsdf",
        overviewLink: "dfdfdfdf",
        photoLink: "dfdfdfdf",
        videoLink: "dfdsfgdsfgd",
      })
    );
  },
};

const GetFixtures: CustomCommand = {
  command: "GetFixtures",
  description: "get fixtures",
  handler: () => {
    store.dispatch(UserActions.getFixtures.request());
  },
};

const Clubs: CustomCommand = {
  command: "GetClubs",
  description: "get clubs",
  handler: () => {
    store.dispatch(UserActions.getClubs.request({ index: "0" }));
  },
};

const Social: CustomCommand = {
  command: "GetSocial",
  description: "get social",
  handler: () => {
    store.dispatch(UserActions.getSocial.request({ index: "0" }));
  },
};

const EditSocial: CustomCommand = {
  command: "EditSocial",
  description: "edit social",
  handler: () => {
    store.dispatch(
      UserActions.editSocial.request({
        id: "613cb112ce06ba5084d8587c",
        url: "https://www.facebook.com/campaign/4444",
      })
    );
  },
};

const DeleteClub: CustomCommand = {
  command: "DeleteClub",
  description: "delete club",
  handler: () => {
    store.dispatch(
      UserActions.deleteClub.request({ id: "613b84e494038aba7a0ac3ea" })
    );
  },
};

const DeleteClubMember: CustomCommand = {
  command: "DeleteClubMember",
  description: "Delete Club Member",
  handler: () => {
    store.dispatch(
      UserActions.deleteClubMember.request({
        id: "613b84e494038aba7a0ac3ea",
        userId: "613b84e494038aba7a0ac3ea",
      })
    );
  },
};

const GetEvents: CustomCommand = {
  command: "GetEvents",
  description: "get events",
  handler: () => {
    store.dispatch(UserActions.getEvents.request({ index: "0" }));
  },
};

const CreateEvent: CustomCommand = {
  command: "CreateEvent",
  description: "create event",
  handler: () => {
    store.dispatch(UserActions.createEvent.request());
  },
};

const DeleteEvent: CustomCommand = {
  command: "DeleteEvent",
  description: "delete event",
  handler: () => {
    store.dispatch(UserActions.deleteEvent.request());
  },
};

const VoteSearch: CustomCommand = {
  command: "VoteSearch",
  description: "vote search",
  handler: () => {
    store.dispatch(
      UserActions.voteSearch.request({
        fixture: 711977,
        sortBy: "fixture",
        sort: -1,
      })
    );
  },
};

const EditClub: CustomCommand = {
  command: "EditClub",
  description: "EditClub",
  handler: () => {
    store.dispatch(
      UserActions.editClub.request({
        id: "61671a922a9d82efe41aa25f",
        name: "fixture",
        address: "Maidan Nezalezhnosti 2, Kyiv",
        facebook: "https://www.facebook.com/campaign/4444",
        telegram: "https://t.me/sdfsdfsdf",
        instagram: "https://www.instagram.com/sdfdsfsdf",
      })
    );
  },
};

const GetPredictions: CustomCommand = {
  command: "GetPredictions",
  description: "get predictions",
  handler: () => {
    store.dispatch(UserActions.getPredictions.request({ fixtureId: "711977" }));
  },
};

const GetUsers: CustomCommand = {
  command: "GetUsers",
  description: "Get Users",
  handler: () => {
    store.dispatch(UserActions.getUsers.request({ index: "0" }));
  },
};

const DeleteUser: CustomCommand = {
  command: "DeleteUser",
  description: "Delete User",
  handler: () => {
    store.dispatch(UserActions.deleteUser.request({ userId: "0" }));
  },
};

const GetClubMembers: CustomCommand = {
  command: "GetClubMembers",
  description: "Get Club Members",
  handler: () => {
    store.dispatch(
      UserActions.getClubMembers.request({
        id: "616e84be1be870a281b7531f",
        index: "0",
      })
    );
  },
};

const GetEventMembers: CustomCommand = {
  command: "GetEventMembers",
  description: "Get Event Members",
  handler: () => {
    store.dispatch(
      UserActions.getEventMembers.request({
        clubId: "616e84be1be870a281b7531f",
        eventId: "616e84be1be870a281b7531f",
        index: "0",
      })
    );
  },
};

const GetClubEvents: CustomCommand = {
  command: "GetClubEvents",
  description: "Get Club Events",
  handler: () => {
    store.dispatch(
      UserActions.getClubEvents.request({
        index: "0",
      })
    );
  },
};

const GetClubEventsById: CustomCommand = {
  command: "GetClubEventsById",
  description: "Get ClubEvents By Id",
  handler: () => {
    store.dispatch(
      UserActions.getClubEventsById.request({
        clubId: "0",
        eventId: "0",
      })
    );
  },
};

const SendNotification: CustomCommand = {
  command: "SendNotification",
  description: "Send Notification",
  handler: () => {
    store.dispatch(
      UserActions.sendNotification.request({
        birthDay: { min: 2, max: 10 },
        sex: "m",
        title: { en: "Test Push" },
        msg: { en: "Welcome to Ruh" },
      })
    );
  },
};

const commands = [
  Logout,
  Login,
  Players,
  Sponsors,
  EditPlayers,
  CreateSponsor,
  EditSponsors,
  DeleteSponsor,
  EditFixtures,
  GetFixtures,
  Clubs,
  Social,
  EditSocial,
  DeleteClub,
  GetEvents,
  CreateEvent,
  DeleteEvent,
  VoteSearch,
  GetPredictions,
  EditClub,
  GetUsers,
  DeleteUser,
  GetClubMembers,
  GetEventMembers,
  GetClubEvents,
  GetClubEventsById,
  DeleteClubMember,
  SendNotification,
];

export { commands };
