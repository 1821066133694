import { useSelector, useDispatch } from "react-redux";
import { UserActions } from "../logic/user/UserRedux";
import { useState, useEffect, useRef } from "react";
import { userSelector } from "../logic/user/UserSelectors";
import { fDate } from "src/utils/formatTime";
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import {
  UserMoreMenu3,
  UserMoreMenu,
  UserMoreMenu4,
} from "../components/_dashboard/user";
import { UserListHead } from "../components/_dashboard/user";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import { Icon } from "@iconify/react";

// ----------------------------------------------------------------------

export default function Clubs() {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [requestId, setRequestId] = useState("");
  const [userData, setUserData] = useState([]);
  const [userData1, setUserData1] = useState([]);
  const [idOfClub, setId] = useState();
  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(0);
  const [name, setName] = useState();
  const [address, setAddress] = useState();
  const [facebook, setFacebook] = useState();
  const [instagram, setInstagram] = useState();
  const [telegram, setTelegram] = useState();
  const [isOpenModal1, setIsOpenModal1] = useState(false);
  const [isOpenModal2, setIsOpenModal2] = useState(false);

  const [userInput, setUserInput] = useState("");
  const [userInput2, setUserInput2] = useState("");

  const ref = useRef(null);

  const {
    getClubs: { data: getClubsData, fetching: getClubsFetching },
    getClubMembers: {
      data: getClubMembersData,
      fetching: getClubMembersFetching,
    },
  } = useSelector(userSelector);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };
  const handleFacebookChange = (event) => {
    setFacebook(event.target.value);
  };
  const handleInstagramChange = (event) => {
    setInstagram(event.target.value);
  };
  const handleTelegramChange = (event) => {
    setTelegram(event.target.value);
  };

  const handleSearchChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleSearchChange2 = (event) => {
    setUserInput2(event.target.value);
  };

  const getClubMembersHandler = (clubIndex) => {
    setUserData1([]);
    const i = clubIndex;
    const ide = getClubsData.clubs[i]._id;
    setId(ide);
    dispatch(
      UserActions.getClubMembers.request({
        id: ide,
        index: "0",
      })
    );
  };

  const handleLoadEarlierMembers = () => {
    dispatch(
      UserActions.getClubMembers.request({
        id: idOfClub,
        index: page1 + userData1.length,
      })
    );
    setPage1(page1 + userData1.length);
  };

  useEffect(() => {
    if (getClubMembersData !== undefined) {
      if (userData1.length > 0 && page1 > 0) {
        setUserData1([...userData1, ...getClubMembersData.members]);
      } else {
        setUserData1(getClubMembersData.members);
      }
    }
  }, [getClubMembersData]);

  const handleLoadEarlier = () => {
    dispatch(UserActions.getClubs.request({ index: page + userData.length }));
    setPage(page + userData.length);
  };

  useEffect(() => {
    dispatch(UserActions.getClubs.request({ index: 0 }));
    // () => {
    //   dispatch(UserActions.getUsers.success(undefined));
    // };
  }, []);

  useEffect(() => {
    if (getClubsData !== undefined) {
      if (userData.length > 0 && page > 0) {
        setUserData([...userData, ...getClubsData.clubs]);
      } else {
        setUserData(getClubsData.clubs);
      }
    }
  }, [getClubsData]);

  const deleteClubHandler = (requestId) => {
    const i = requestId;
    const ide = getClubsData.clubs[i]._id;
    dispatch(
      UserActions.deleteClub.request({
        id: ide,
      })
    );
  };

  const deleteClubMemberHandler = (clubIdIndex, memberIdIndex) => {
    const i = clubIdIndex;
    const mi = memberIdIndex;
    const ide = getClubsData.clubs[i]._id;
    const mide = getClubMembersData.members[mi]._id;
    dispatch(
      UserActions.deleteClubMember.request({
        id: ide,
        userId: mide,
      })
    );
  };

  const editClubHandler = (
    requestId,
    name,
    address,
    facebook,
    instagram,
    telegram
  ) => {
    const i = requestId;
    const ide = getClubsData.clubs[i]._id;
    dispatch(
      UserActions.editClub.request({
        id: ide,
        name: name ? name : undefined,
        address: address ? address : undefined,
        facebook: facebook ? facebook : undefined,
        instagram: instagram ? instagram : undefined,
        telegram: telegram ? telegram : undefined,
      })
    );
  };

  const divStyle = {
    display: "flex",
    justifyContent: "center",
    outline: "none",
    flexDirection: "column",
    padding: 40,
  };
  const divStyle2 = {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    outline: "none",
  };

  const inputStyle = {
    display: "flex",
    outline: "none",
  };

  const inputStyle2 = {
    display: "flex",
    outline: "none",
    paddingBottom: 10,
    paddingTop: 10,
  };

  const inputStyle3 = {
    display: "flex",
    outline: "none",
    paddingTop: 20,
    paddingLeft: 14,
    paddingRight: 14,
    paddingBottom: 15,
  };

  const divStyle5 = {
    outline: "none",
    maxWidth: 400,
  };

  const buttonStyle = {
    display: "flex",
    outline: "none",
    marginRight: 10,
    alignSelf: "center",
  };

  const buttonSt = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
  };

  const buttonSt2 = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 20,
  };

  const TABLE_HEAD = [
    { id: "Club", label: "Club Name", alignRight: false },
    { id: "Location", label: "Location", alignRight: false },
  ];

  return (
    <>
      <Page title="Ruh | Clubs">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}>
            <Typography variant="h4" gutterBottom>
              {"Клубы"}
            </Typography>
            <TextField
              value={userInput}
              onChange={handleSearchChange}
              style={inputStyle2}
              id="standard-basic"
              placeholder={"Search..."}
            />
          </Stack>

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} />
                  {userData !== undefined ? (
                    <TableBody>
                      {userData
                        .filter((itm: any) =>
                          itm.name
                            .toLowerCase()
                            .includes(userInput.toLowerCase())
                        )
                        .map((row, index) => {
                          const {
                            _id,
                            name,
                            origPhoto,
                            address,
                            facebook,
                            telegram,
                            instagram,
                          } = row;
                          const isItemSelected = selected.indexOf(name) !== -1;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}>
                              <>
                                <TableCell align="left"></TableCell>
                                <TableCell>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}>
                                    <Avatar alt={name} src={origPhoto} />
                                    <Typography variant="subtitle2" noWrap>
                                      {name}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">{address}</TableCell>
                                <TableCell align="right">
                                  <UserMoreMenu4
                                    onDelete={() => {
                                      setRequestId(index);
                                      deleteClubHandler(index);
                                    }}
                                    onEdit={() => {
                                      setIsOpenModal1(true);
                                      setRequestId(index);
                                      if (address) {
                                        setAddress(address);
                                      }
                                      if (facebook) {
                                        setFacebook(facebook);
                                      }
                                      if (telegram) {
                                        setTelegram(telegram);
                                      }
                                      if (instagram) {
                                        setInstagram(instagram);
                                      }
                                    }}
                                    onMembers={() => {
                                      setRequestId(index);
                                      getClubMembersHandler(index);
                                      setIsOpenModal2(true);
                                    }}
                                  />
                                </TableCell>
                              </>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
          <div style={buttonSt}>
            <Button
              onClick={() => handleLoadEarlier()}
              variant="contained"
              style={buttonStyle}
              // startIcon={<Icon icon={plusFill}
              //  />}
            >
              Load more
            </Button>
          </div>
        </Container>
      </Page>
      <Modal
        style={divStyle2}
        open={isOpenModal1}
        onClose={() => {
          setIsOpenModal1(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <Card style={divStyle} elevation={3}>
          <TextField
            style={inputStyle2}
            value={name}
            onChange={handleNameChange}
            id="standard-basic"
            label="Name"
          />
          <TextField
            style={inputStyle}
            value={address}
            onChange={handleAddressChange}
            id="standard-basic"
            label="Address"
          />
          <TextField
            value={facebook}
            onChange={handleFacebookChange}
            style={inputStyle2}
            id="standard-basic"
            label="Facebook"
          />
          <TextField
            value={telegram}
            onChange={handleTelegramChange}
            style={inputStyle2}
            id="standard-basic"
            label="Telegram"
          />
          <TextField
            value={instagram}
            onChange={handleInstagramChange}
            style={inputStyle2}
            id="standard-basic"
            label="Instagram"
          />
          <Button
            onClick={() => {
              editClubHandler(
                requestId,
                name,
                address,
                facebook,
                instagram,
                telegram
              );
              setIsOpenModal1(false);
            }}
            fullWidth
            target="_blank"
            variant="contained">
            Save
          </Button>
        </Card>
      </Modal>

      <Modal
        style={divStyle2}
        open={isOpenModal2}
        onClose={() => {
          setIsOpenModal2(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <Container style={divStyle5}>
          <Card>
            <Scrollbar>
              <TableContainer sx={{ maxWidth: 400, maxHeight: 600 }}>
                <TextField
                  value={userInput2}
                  onChange={handleSearchChange2}
                  style={inputStyle3}
                  id="standard-basic"
                  placeholder={"Search..."}
                />
                <Table>
                  {/* <UserListHead headLabel={TABLE_HEAD2} /> */}
                  {userData1 !== undefined ? (
                    <TableBody>
                      {userData1
                        .filter((itm: any) =>
                          itm.name
                            .toLowerCase()
                            .includes(userInput2.toLowerCase())
                        )
                        .map((row, index) => {
                          const { _id, name, surname, origPhoto } = row;
                          const isItemSelected = selected.indexOf(name) !== -1;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}>
                              <>
                                <TableCell>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}>
                                    <Avatar alt={name} src={origPhoto} />
                                    <Typography variant="subtitle2" noWrap>
                                      {name + " " + surname}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="right">
                                  <IconButton
                                    onClick={() => {
                                      deleteClubMemberHandler(requestId, index);
                                      getClubMembersHandler(requestId);
                                    }}>
                                    <Icon
                                      icon={trash2Outline}
                                      width={20}
                                      height={20}
                                    />
                                  </IconButton>
                                </TableCell>
                              </>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
            </Scrollbar>
            <div style={buttonSt2}>
              <Button
                onClick={() => handleLoadEarlierMembers()}
                variant="contained"
                style={buttonStyle}
                // startIcon={<Icon icon={plusFill}
                //  />}
              >
                Load more
              </Button>
            </div>
          </Card>
        </Container>
      </Modal>
    </>
  );
}
