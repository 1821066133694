import { AppConfig } from "AppConfig";

const config: AppConfig = {
  useReactotron: false,
  systemId: "ruh_admin_web",
  authURL: "https://app.fancreed.com/api",
  baseURL: "https://app.fancreed.com/api",
};

export { config };
