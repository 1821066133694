import { useSelector, useDispatch } from "react-redux";
import { UserActions } from "../logic/user/UserRedux";
import { useState, useEffect } from "react";
import { userSelector } from "../logic/user/UserSelectors";
import { fDate } from "src/utils/formatTime";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";

import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  TextField,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { UserMoreMenu } from "../components/_dashboard/user";
import { UserListHead } from "../components/_dashboard/user";

// ----------------------------------------------------------------------

export default function Events() {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [time, setTime] = useState("");
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longtitude, setLongtitude] = useState("");
  const [requestId, setRequestId] = useState("");
  const [userData, setUserData] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModal1, setIsOpenModal1] = useState(false);

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };
  const handleLatitudeChange = (event) => {
    setLatitude(event.target.value);
  };
  const handleLongtitudeChange = (event) => {
    setLongtitude(event.target.value);
  };
  const {
    getEvents: { data: getEventsData, fetching: getEventsFetching },
  } = useSelector(userSelector);

  useEffect(() => {
    dispatch(UserActions.getEvents.request({ index: 0 }));
  }, []);

  useEffect(() => {
    setUserData(getEventsData);
  }, [getEventsData]);

  const createEventHandler = (time, title, location, latitude, longitude) => {
    dispatch(
      UserActions.createEvent.request({
        type: "matchDay",
        time: time,
        title: title,
        location: location,
        coords: {
          latitude: latitude,
          longitude: longitude,
        },
      })
    );
    setTime("");
    setLocation("");
    setTitle("");
    setLongtitude("");
    setLatitude("");
  };

  const deleteEventHandler = (requestId) => {
    const i = requestId;
    const ide = getEventsData.events[i]._id;
    dispatch(
      UserActions.deleteEvent.request({
        id: ide,
      })
    );
  };

  const editEventHandler = (
    requestId,
    time,
    title,
    location,
    latitude,
    longitude
  ) => {
    const i = requestId;
    const ide = getEventsData.events[i]._id;
    dispatch(
      UserActions.editEvent.request({
        id: ide,
        type: "matchDay",
        time: time ? time : getEventsData.events[i].time,
        title: title ? title : getEventsData.events[i].title,
        location: location ? location : getEventsData.events[i].location,
        coords: {
          latitude: latitude
            ? latitude
            : getEventsData.events[i].coords.latitude,
          longitude: longitude
            ? longtitude
            : getEventsData.events[i].coords.longtitude,
        },
      })
    );
    setTime("");
    setLocation("");
    setTitle("");
    setLongtitude("");
    setLatitude("");
  };

  const divStyle = {
    display: "flex",
    justifyContent: "center",
    outline: "none",
    flexDirection: "column",
    padding: 40,
  };
  const divStyle2 = {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    outline: "none",
  };

  const inputStyle = {
    display: "flex",
    outline: "none",
  };

  const inputStyle2 = {
    display: "flex",
    outline: "none",
    paddingBottom: 10,
    paddingTop: 10,
  };

  const buttonStyle = {
    display: "flex",
    outline: "none",
    marginRight: 10,
  };

  const TABLE_HEAD = [
    { id: "Title", label: "Title", alignRight: false },
    { id: "Type", label: "Type", alignRight: false },
    { id: "Time", label: "Time", alignRight: false },
    { id: "Location", label: "Location", alignRight: false },
  ];

  return (
    <>
      <Page title="Ruh | MatchDay">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}>
            <Typography variant="h4" gutterBottom>
              {"День Матча"}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between">
              <Button
                onClick={() => {
                  setIsOpenModal(true);
                }}
                variant="contained"
                style={buttonStyle}
                startIcon={<Icon icon={plusFill} />}>
                New Event
              </Button>
            </Stack>
          </Stack>

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} />
                  {userData !== undefined ? (
                    <TableBody>
                      {userData.events
                        .map((row, index) => {
                          const { _id, name, time, title, location, kind } =
                            row;
                          const isItemSelected = selected.indexOf(_id) !== -1;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}>
                              <>
                                <TableCell align="left"></TableCell>
                                <TableCell>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}>
                                    <Avatar
                                      alt={name}
                                      src={
                                        "https://image.pngaaa.com/571/4114571-middle.png"
                                      }
                                    />
                                    <Typography variant="subtitle2" noWrap>
                                      {title}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">{kind}</TableCell>
                                <TableCell align="left">{time}</TableCell>
                                <TableCell align="left">{location}</TableCell>
                                <TableCell align="right">
                                  <UserMoreMenu
                                    onDelete={() => {
                                      deleteEventHandler(index);
                                    }}
                                    onEdit={() => {
                                      setIsOpenModal1(true);
                                      setRequestId(index);
                                    }}
                                  />
                                </TableCell>
                              </>
                            </TableRow>
                          );
                        })
                        .reverse()}
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Container>
      </Page>
      <Modal
        style={divStyle2}
        open={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <Card style={divStyle} elevation={3}>
          <TextField
            style={inputStyle2}
            value={title}
            onChange={handleTitleChange}
            id="standard-basic"
            label="Title"
          />
          <TextField
            style={inputStyle}
            value={time}
            onChange={handleTimeChange}
            id="standard-basic"
            label="Time like 22:00"
          />
          <TextField
            value={location}
            onChange={handleLocationChange}
            style={inputStyle2}
            id="standard-basic"
            label="Location"
          />
          <TextField
            value={latitude}
            onChange={handleLatitudeChange}
            style={inputStyle2}
            id="standard-basic"
            label="latitude"
          />
          <TextField
            value={longtitude}
            onChange={handleLongtitudeChange}
            style={inputStyle2}
            id="standard-basic"
            label="longtitude"
          />
          <Button
            onClick={() => {
              createEventHandler(time, title, location, latitude, longtitude);
              setIsOpenModal(false);
            }}
            fullWidth
            target="_blank"
            variant="contained">
            Save
          </Button>
        </Card>
      </Modal>
      <Modal
        style={divStyle2}
        open={isOpenModal1}
        onClose={() => {
          setIsOpenModal1(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <Card style={divStyle} elevation={3}>
          <TextField
            style={inputStyle2}
            value={title}
            onChange={handleTitleChange}
            id="standard-basic"
            label="Title"
          />
          <TextField
            style={inputStyle}
            value={time}
            onChange={handleTimeChange}
            id="standard-basic"
            label="Time like 22:00"
          />
          <TextField
            value={location}
            onChange={handleLocationChange}
            style={inputStyle2}
            id="standard-basic"
            label="Location"
          />
          <TextField
            value={longtitude}
            onChange={handleLongtitudeChange}
            style={inputStyle2}
            id="standard-basic"
            label="longtitude"
          />
          <TextField
            value={latitude}
            onChange={handleLatitudeChange}
            style={inputStyle2}
            id="standard-basic"
            label="latitude"
          />
          <Button
            onClick={() => {
              editEventHandler(
                requestId,
                time,
                title,
                location,
                latitude,
                longtitude
              );
              setIsOpenModal1(false);
            }}
            fullWidth
            target="_blank"
            variant="contained">
            Save
          </Button>
        </Card>
      </Modal>
    </>
  );
}
