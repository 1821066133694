import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import Social from "./pages/Social";
import Team from "./pages/Team";
import Fixtures from "./pages/Fixtures";
import Clubs from "./pages/Clubs";
import Events from "./pages/Events";
import Users from "./pages/Users";
import Partners from "./pages/Partners";
import Predictions from "./pages/Predictions";
import ClubEvents from "./pages/ClubEvents";
import Notifications from "./pages/Notifications";
import NotFound from "./pages/Page404";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "/home", element: <Navigate to="/home/app" replace /> },
        { path: "/", element: <Navigate to="/home/app" replace /> },
        { path: "home/app", element: <Social /> },
        { path: "home/partners", element: <Partners /> },
        { path: "home/team", element: <Team /> },
        { path: "home/fixtures", element: <Fixtures /> },
        { path: "home/clubs", element: <Clubs /> },
        { path: "home/events", element: <Events /> },
        { path: "home/predictions", element: <Predictions /> },
        { path: "home/users", element: <Users /> },
        { path: "home/clubevents", element: <ClubEvents /> },
        { path: "home/notifications", element: <Notifications /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
