import { Action } from "@reduxjs/toolkit";
import { ApiResponse } from "apisauce";
import _ from "lodash";
import { put, call, select } from "redux-saga/effects";
import { api, extractError, RequestError } from "../../api";
import { SendNotificationResponse, UserActions } from "./UserRedux";
import { takeLeading } from "./../../store/sagaHelper";
import { store } from "./../../store/store";
import { BaseErrorType } from "./../../store/restHelper.d";
import { userSelector } from "./UserSelectors";
import {
  CreateSponsorResponse,
  EditPlayersResponse,
  GetPlayersResponse,
  GetSponsorsResponse,
  LoginResponse,
  LogoutResponse,
  EditSponsorsResponse,
  DeleteSponsorResponse,
  EditFixturesResponse,
  GetFixturesResponse,
  GetClubsResponse,
  GetSocialResponse,
  EditSocialResponse,
  GetEventsResponse,
  CreateEventResponse,
  EditEventResponse,
  VoteSearchResponse,
  GetPredictionsResponse,
  EditClubResponse,
  GetUsersResponse,
  DeleteUserResponse,
  GetClubMembersResponse,
  GetEventMembersResponse,
  GetClubEventsResponse,
  GetClubEventsByIdResponse,
  DeleteClubEventResponse,
  DeleteClubMemberResponse,
  DeleteEventMemberResponse,
  GetClubEventsListByIdResponse,
  GetSelfResponse,
} from "./../../types/api";

function* loginRequest(action: Action) {
  if (UserActions.login.request.match(action)) {
    try {
      let { login, storeToken, password } = action.payload;
      const error = {};
      const loginResponse: ApiResponse<LoginResponse> = yield call(
        api.loginPost,
        {
          login,
          password,
        }
      );
      // console.log("loginResponse", loginResponse);
      if (loginResponse.ok && loginResponse.data) {
        const responseData = loginResponse.data;
        yield put(
          UserActions.login.success({
            userId: responseData?.userId,
            userRole: responseData?.userRole,
            access_token: responseData?.access_token,
            token_type: responseData?.token_type,
          })
        );
        // Navigation.push(navigationService.getCurrentScreenId(), {
        //    component: {
        //       name: responseData.userRole === 'student' ? "StudentHomeScreen" : "HomeScreen",
        //       options: {
        //          popGesture: false,
        //          animations: { push: { enabled: true } }
        //       }
        //    }
        // });
        // if (storeToken === true) {
        //    storeTokenToAsyncStorage(responseData.access_token);
        //    storeUserIdToAsyncStorage(responseData.userId);
        //    storeUserRoleToAsyncStorage(responseData?.userRole);
        // }
        store.dispatch(
          UserActions.storeTokenToRedux(responseData.access_token)
        );
        store.dispatch(UserActions.storeUserIdToRedux(responseData.userId));
        store.dispatch(UserActions.storeUserRoleToRedux(responseData.userRole));
        yield put(UserActions.getSelf.request());
      } else {
        _.set(error, "description", loginResponse?.data?.message);
        _.set(error, "code", loginResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.login.failure(extractError(error)));
    }
  }
}

function* logout(action: Action) {
  const state: ReturnType<typeof userSelector> = yield select(userSelector);
  let token = state.userToken;
  if (UserActions.logout.request.match(action)) {
    try {
      // deleteTokenFromAsyncStorage();
      // deleteUserIdFromAsyncStorage();
      // deleteUserRoleFromAsyncStorage();
      yield put(UserActions.storeTokenToRedux(""));
      yield put(UserActions.storeUserIdToRedux(undefined));
      yield put(UserActions.storeUserRoleToRedux(undefined));
      //yield call(showDefaultScreen);

      if (token) {
        const logoutResponse: ApiResponse<LogoutResponse> = yield call(
          api.logoutGet,
          token
        );
      }
      yield put(UserActions.logout.success());
    } catch (error) {
      yield put(UserActions.logout.failure(extractError(error)));
    }
  }
}

function* tokenRelevanceCheck(action: Action) {
  if (UserActions.tokenRelevanceCheck.request.match(action)) {
    try {
      let { error } = action.payload;

      if (error.code !== 401) {
        // 401 means 'not authorized'
        yield put(
          UserActions.tokenRelevanceCheck.success({
            tokenExpiried: false,
          })
        );
      } else {
        yield put(UserActions.logout.request());
        yield put(
          UserActions.tokenRelevanceCheck.success({
            tokenExpiried: true,
          })
        );
      }
    } catch (error) {
      yield put(UserActions.logout.request());
      yield put(UserActions.tokenRelevanceCheck.failure(extractError(error)));
    }
  }
}

function* getPlayersRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.getPlayers.request.match(action)) {
    try {
      const error = {};
      const { index } = action.payload;
      const getPlayersResponse: ApiResponse<GetPlayersResponse> = yield call(
        api.getPlayersGet,
        { index },
        token
      );
      if (getPlayersResponse.ok && getPlayersResponse.data) {
        const responseData = getPlayersResponse.data;
        yield put(UserActions.getPlayers.success(responseData));
      } else {
        _.set(error, "description", getPlayersResponse.data?.message);
        _.set(error, "code", getPlayersResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.getPlayers.failure(extractError(error)));
    }
  }
}

function* editPlayersRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.editPlayers.request.match(action)) {
    try {
      const error = {};
      const { id, actualName, ruhLink, actualPhoto, visible } = action.payload;
      const editPlayersResponse: ApiResponse<EditPlayersResponse> = yield call(
        api.editPlayersPost,
        { id, actualName, ruhLink, actualPhoto, visible },
        token
      );
      if (editPlayersResponse.ok && editPlayersResponse.data) {
        const responseData = editPlayersResponse.data;
        yield put(UserActions.editPlayers.success(responseData));
        yield put(UserActions.getPlayers.request({ index: "0" }));
      } else {
        _.set(error, "description", editPlayersResponse.data?.message);
        _.set(error, "code", editPlayersResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.editPlayers.failure(extractError(error)));
    }
  }
}

function* getSponsorsRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.getSponsors.request.match(action)) {
    try {
      const error = {};
      const { index } = action.payload;
      const getSponsorsResponse: ApiResponse<GetSponsorsResponse> = yield call(
        api.getSponsorsGet,
        { index },
        token
      );
      if (getSponsorsResponse.ok && getSponsorsResponse.data) {
        const responseData = getSponsorsResponse.data;
        yield put(UserActions.getSponsors.success(responseData));
      } else {
        _.set(error, "description", getSponsorsResponse.data?.message);
        _.set(error, "code", getSponsorsResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.getSponsors.failure(extractError(error)));
    }
  }
}

function* createSponsorRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.createSponsor.request.match(action)) {
    try {
      const error = {};
      const { name, url, priority, image } = action.payload;
      const createSponsorResponse: ApiResponse<CreateSponsorResponse> =
        yield call(
          api.createSponsorPost,
          { name, url, priority, image },
          token
        );
      if (createSponsorResponse.ok && createSponsorResponse.data) {
        const responseData = createSponsorResponse.data;
        yield put(UserActions.createSponsor.success(responseData));
      } else {
        _.set(error, "description", createSponsorResponse.data?.message);
        _.set(error, "code", createSponsorResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.createSponsor.failure(extractError(error)));
    }
  }
}

function* editSponsorsRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.editSponsors.request.match(action)) {
    try {
      const error = {};
      const { id, sponsors } = action.payload;
      const editSponsorsResponse: ApiResponse<EditSponsorsResponse> =
        yield call(api.editSponsorsPost, { id, sponsors }, token);
      if (editSponsorsResponse.ok && editSponsorsResponse.data) {
        const responseData = editSponsorsResponse.data;
        yield put(UserActions.editSponsors.success(responseData));
        yield put(UserActions.getSponsors.request({ index: "0" }));
      } else {
        _.set(error, "description", editSponsorsResponse.data?.message);
        _.set(error, "code", editSponsorsResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.editSponsors.failure(extractError(error)));
    }
  }
}

function* deleteSponsorRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.deleteSponsor.request.match(action)) {
    try {
      const error = {};
      const { id } = action.payload;
      const deleteSponsorResponse: ApiResponse<DeleteSponsorResponse> =
        yield call(api.deleteSponsorDelete, { id }, token);
      if (deleteSponsorResponse.ok && deleteSponsorResponse.data) {
        const responseData = deleteSponsorResponse.data;
        yield put(UserActions.deleteSponsor.success(responseData));
      } else {
        _.set(error, "description", deleteSponsorResponse.data?.message);
        _.set(error, "code", deleteSponsorResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.deleteSponsor.failure(extractError(error)));
    }
  }
}

function* editFixturesRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.editFixtures.request.match(action)) {
    try {
      const error = {};
      const {
        id,
        ticketLink,
        previewLink,
        overviewLink,
        photoLink,
        videoLink,
      } = action.payload;
      const editFixturesResponse: ApiResponse<EditFixturesResponse> =
        yield call(
          api.editFixturesPost,
          { id, ticketLink, previewLink, overviewLink, photoLink, videoLink },
          token
        );
      if (editFixturesResponse.ok && editFixturesResponse.data) {
        const responseData = editFixturesResponse.data;
        yield put(UserActions.editFixtures.success(responseData));
        yield put(UserActions.getFixtures.request());
      } else {
        _.set(error, "description", editFixturesResponse.data?.message);
        _.set(error, "code", editFixturesResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.editFixtures.failure(extractError(error)));
    }
  }
}

function* getFixturesRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.getFixtures.request.match(action)) {
    try {
      const error = {};
      const getFixturesResponse: ApiResponse<GetFixturesResponse> = yield call(
        api.getFixturesGet,
        token
      );
      if (getFixturesResponse.ok && getFixturesResponse.data) {
        const responseData = getFixturesResponse.data;
        yield put(UserActions.getFixtures.success(responseData));
      } else {
        _.set(error, "description", getFixturesResponse.data?.message);
        _.set(error, "code", getFixturesResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.getFixtures.failure(extractError(error)));
    }
  }
}

function* getClubsRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.getClubs.request.match(action)) {
    try {
      const error = {};
      const { index } = action.payload;
      const getClubsResponse: ApiResponse<GetClubsResponse> = yield call(
        api.getClubsGet,
        { index },
        token
      );
      if (getClubsResponse.ok && getClubsResponse.data) {
        const responseData = getClubsResponse.data;
        yield put(UserActions.getClubs.success(responseData));
      } else {
        _.set(error, "description", getClubsResponse.data?.message);
        _.set(error, "code", getClubsResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.getClubs.failure(extractError(error)));
    }
  }
}

function* getSocialRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.getSocial.request.match(action)) {
    try {
      const error = {};
      const { index } = action.payload;
      const getSocialResponse: ApiResponse<GetSocialResponse> = yield call(
        api.getSocialGet,
        { index },
        token
      );
      if (getSocialResponse.ok && getSocialResponse.data) {
        const responseData = getSocialResponse.data;
        yield put(UserActions.getSocial.success(responseData));
      } else {
        _.set(error, "description", getSocialResponse.data?.message);
        _.set(error, "code", getSocialResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.getSocial.failure(extractError(error)));
    }
  }
}

function* editSocialRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.editSocial.request.match(action)) {
    try {
      const error = {};
      const { id, url, image } = action.payload;
      const editSocialResponse: ApiResponse<EditSocialResponse> = yield call(
        api.editSocialPost,
        { id, url, image },
        token
      );
      if (editSocialResponse.ok && editSocialResponse.data) {
        const responseData = editSocialResponse.data;
        yield put(UserActions.editSocial.success(responseData));
        yield put(UserActions.getSocial.request({ index: "0" }));
      } else {
        _.set(error, "description", editSocialResponse.data?.message);
        _.set(error, "code", editSocialResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.editSocial.failure(extractError(error)));
    }
  }
}

function* deleteClubRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.deleteClub.request.match(action)) {
    try {
      const error = {};
      const { id } = action.payload;
      const deleteClubResponse: ApiResponse<any> = yield call(
        api.deleteClubDelete,
        { id },
        token
      );
      if (deleteClubResponse.ok && deleteClubResponse.data) {
        const responseData = deleteClubResponse.data;
        yield put(
          UserActions.deleteClub
            .success
            // responseData
            ()
        );
        yield put(UserActions.getClubs.request({ index: "0" }));
      } else {
        _.set(error, "description", deleteClubResponse.data?.message);
        _.set(error, "code", deleteClubResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.deleteClub.failure(extractError(error)));
    }
  }
}

function* getEventsRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.getEvents.request.match(action)) {
    try {
      const error = {};
      const { index } = action.payload;
      const getEventsResponse: ApiResponse<GetEventsResponse> = yield call(
        api.getEventsGet,
        { index },
        token
      );
      if (getEventsResponse.ok && getEventsResponse.data) {
        const responseData = getEventsResponse.data;
        yield put(UserActions.getEvents.success(responseData));
      } else {
        _.set(error, "description", getEventsResponse.data?.message);
        _.set(error, "code", getEventsResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.getEvents.failure(extractError(error)));
    }
  }
}

function* createEventRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.createEvent.request.match(action)) {
    try {
      const error = {};
      const { time, title, type, location, coords } = action.payload;
      const createEventResponse: ApiResponse<CreateEventResponse> = yield call(
        api.createEventPost,
        { time, title, type, location, coords },
        token
      );
      if (createEventResponse.ok && createEventResponse.data) {
        const responseData = createEventResponse.data;
        yield put(UserActions.createEvent.success(responseData));
        yield put(UserActions.getEvents.request({ index: "0" }));
      } else {
        _.set(error, "description", createEventResponse.data?.message);
        _.set(error, "code", createEventResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.createEvent.failure(extractError(error)));
    }
  }
}

function* deleteEventRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.deleteEvent.request.match(action)) {
    try {
      const error = {};
      const { id } = action.payload;
      const deleteEventResponse: ApiResponse<any> = yield call(
        api.deleteEventDelete,
        { id },
        token
      );
      if (deleteEventResponse.ok && deleteEventResponse.data) {
        const responseData = deleteEventResponse.data;
        yield put(UserActions.deleteEvent.success());
        yield put(UserActions.getEvents.request({ index: "0" }));
      } else {
        _.set(error, "description", deleteEventResponse.data?.message);
        _.set(error, "code", deleteEventResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.deleteEvent.failure(extractError(error)));
    }
  }
}

function* editEventRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.editEvent.request.match(action)) {
    try {
      const error = {};
      const { id, time, title, type, location, coords } = action.payload;
      const editEventResponse: ApiResponse<EditEventResponse> = yield call(
        api.editEventPost,
        { id, time, title, type, location, coords },
        token
      );
      if (editEventResponse.ok && editEventResponse.data) {
        const responseData = editEventResponse.data;
        yield put(UserActions.editEvent.success(responseData));
        yield put(UserActions.getEvents.request({ index: "0" }));
      } else {
        _.set(error, "description", editEventResponse.data?.message);
        _.set(error, "code", editEventResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.editEvent.failure(extractError(error)));
    }
  }
}

function* voteSearchRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.voteSearch.request.match(action)) {
    try {
      const error = {};
      const { fixture, sortBy, sort } = action.payload;
      const voteSearchResponse: ApiResponse<VoteSearchResponse> = yield call(
        api.voteSearchPost,
        { fixture, sortBy, sort },
        token
      );
      if (voteSearchResponse.ok && voteSearchResponse.data) {
        const responseData = voteSearchResponse.data;
        yield put(UserActions.voteSearch.success(responseData));
      } else {
        _.set(error, "description", voteSearchResponse.data?.message);
        _.set(error, "code", voteSearchResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.voteSearch.failure(extractError(error)));
    }
  }
}
function* getPredictionsRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.getPredictions.request.match(action)) {
    try {
      const error = {};
      const { fixtureId } = action.payload;
      const getPredictionsResponse: ApiResponse<GetPredictionsResponse> =
        yield call(api.getPredictionsGet, { fixtureId }, token);
      if (getPredictionsResponse.ok && getPredictionsResponse.data) {
        const responseData = getPredictionsResponse.data;
        yield put(UserActions.getPredictions.success(responseData));
      } else {
        _.set(error, "description", getPredictionsResponse.data?.message);
        _.set(error, "code", getPredictionsResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.getPredictions.failure(extractError(error)));
    }
  }
}

function* editClubRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.editClub.request.match(action)) {
    try {
      const error = {};
      const { id, name, address, facebook, instagram, telegram } =
        action.payload;
      const editClubResponse: ApiResponse<EditClubResponse> = yield call(
        api.editClubPost,
        { id, name, address, facebook, instagram, telegram },
        token
      );
      if (editClubResponse.ok && editClubResponse.data) {
        const responseData = editClubResponse.data;
        yield put(UserActions.editClub.success(responseData));
        yield put(UserActions.getClubs.request({ index: "0" }));
      } else {
        _.set(error, "description", editClubResponse.data?.message);
        _.set(error, "code", editClubResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.editClub.failure(extractError(error)));
    }
  }
}

function* getUsersRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.getUsers.request.match(action)) {
    try {
      const error = {};
      const { index } = action.payload;
      const getUsersResponse: ApiResponse<GetUsersResponse> = yield call(
        api.getUsersGet,
        { index },
        token
      );
      if (getUsersResponse.ok && getUsersResponse.data) {
        const responseData = getUsersResponse.data;
        yield put(UserActions.getUsers.success(responseData));
      } else {
        _.set(error, "description", getUsersResponse.data?.message);
        _.set(error, "code", getUsersResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.getUsers.failure(extractError(error)));
    }
  }
}

function* deleteUserRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.deleteUser.request.match(action)) {
    try {
      const error = {};
      const { userId } = action.payload;
      const deleteUserResponse: ApiResponse<DeleteUserResponse> = yield call(
        api.deleteUserDelete,
        { userId },
        token
      );
      if (deleteUserResponse.ok && deleteUserResponse.data) {
        const responseData = deleteUserResponse.data;
        yield put(UserActions.deleteUser.success(responseData));
        yield put(UserActions.getUsers.request({ index: "0" }));
      } else {
        _.set(error, "description", deleteUserResponse.data?.message);
        _.set(error, "code", deleteUserResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.deleteUser.failure(extractError(error)));
    }
  }
}

function* getClubMembersRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.getClubMembers.request.match(action)) {
    try {
      const error = {};
      const { id, index } = action.payload;
      const getClubMembersResponse: ApiResponse<GetClubMembersResponse> =
        yield call(api.getClubMembersGet, { id, index }, token);
      if (getClubMembersResponse.ok && getClubMembersResponse.data) {
        const responseData = getClubMembersResponse.data;
        yield put(UserActions.getClubMembers.success(responseData));
      } else {
        _.set(error, "description", getClubMembersResponse.data?.message);
        _.set(error, "code", getClubMembersResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.getClubMembers.failure(extractError(error)));
    }
  }
}

function* getEventMembersRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.getEventMembers.request.match(action)) {
    try {
      const error = {};
      const { clubId, eventId, index } = action.payload;
      const getEventMembersResponse: ApiResponse<GetEventMembersResponse> =
        yield call(api.getEventMembersGet, { clubId, eventId, index }, token);
      if (getEventMembersResponse.ok && getEventMembersResponse.data) {
        const responseData = getEventMembersResponse.data;
        yield put(UserActions.getEventMembers.success(responseData));
      } else {
        _.set(error, "description", getEventMembersResponse.data?.message);
        _.set(error, "code", getEventMembersResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.getEventMembers.failure(extractError(error)));
    }
  }
}

function* getClubEventsRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.getClubEvents.request.match(action)) {
    try {
      const error = {};
      const { index } = action.payload;
      const getClubEventsResponse: ApiResponse<GetClubEventsResponse> =
        yield call(api.getClubEventsGet, { index }, token);
      if (getClubEventsResponse.ok && getClubEventsResponse.data) {
        const responseData = getClubEventsResponse.data;
        yield put(UserActions.getClubEvents.success(responseData));
      } else {
        _.set(error, "description", getClubEventsResponse.data?.message);
        _.set(error, "code", getClubEventsResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.getClubEvents.failure(extractError(error)));
    }
  }
}

function* getClubEventsByIdRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.getClubEventsById.request.match(action)) {
    try {
      const error = {};
      const { clubId, eventId } = action.payload;

      const getClubEventsByIdResponse: ApiResponse<GetClubEventsByIdResponse> =
        yield call(api.getClubEventsByIdGet, { clubId, eventId }, token);
      if (getClubEventsByIdResponse.ok && getClubEventsByIdResponse.data) {
        const responseData = getClubEventsByIdResponse.data;
        yield put(UserActions.getClubEventsById.success(responseData));
      } else {
        _.set(error, "description", getClubEventsByIdResponse.data?.message);
        _.set(error, "code", getClubEventsByIdResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.getClubEventsById.failure(extractError(error)));
    }
  }
}

function* deleteClubEventRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.deleteClubEvent.request.match(action)) {
    try {
      const error = {};
      const { clubId, eventId } = action.payload;
      const deleteClubEventResponse: ApiResponse<DeleteClubEventResponse> =
        yield call(api.deleteClubEventDelete, { clubId, eventId }, token);
      if (deleteClubEventResponse.ok && deleteClubEventResponse.data) {
        const responseData = deleteClubEventResponse.data;
        yield put(UserActions.deleteClubEvent.success(responseData));
        yield put(UserActions.getClubEvents.request({ index: "0" }));
      } else {
        _.set(error, "description", deleteClubEventResponse.data?.message);
        _.set(error, "code", deleteClubEventResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.deleteClubEvent.failure(extractError(error)));
    }
  }
}

function* deleteClubMemberRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.deleteClubMember.request.match(action)) {
    try {
      const error = {};
      const { id, userId } = action.payload;

      const deleteClubMemberResponse: ApiResponse<DeleteClubMemberResponse> =
        yield call(api.deleteClubMemberDelete, { id, userId }, token);
      if (deleteClubMemberResponse.ok && deleteClubMemberResponse.data) {
        const responseData = deleteClubMemberResponse.data;
        yield put(UserActions.deleteClubMember.success(responseData));
      } else {
        _.set(error, "description", deleteClubMemberResponse.data?.message);
        _.set(error, "code", deleteClubMemberResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.deleteClubMember.failure(extractError(error)));
    }
  }
}

function* deleteEventMemberRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.deleteEventMember.request.match(action)) {
    try {
      const error = {};
      const { clubId, eventId, userId } = action.payload;
      const deleteEventMemberResponse: ApiResponse<DeleteEventMemberResponse> =
        yield call(
          api.deleteEventMemberDelete,
          { clubId, eventId, userId },
          token
        );
      if (deleteEventMemberResponse.ok && deleteEventMemberResponse.data) {
        const responseData = deleteEventMemberResponse.data;
        yield put(UserActions.deleteEventMember.success(responseData));
      } else {
        _.set(error, "description", deleteEventMemberResponse.data?.message);
        _.set(error, "code", deleteEventMemberResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.deleteEventMember.failure(extractError(error)));
    }
  }
}

function* getClubEventsListByIdRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.getClubEventsListById.request.match(action)) {
    try {
      const error = {};
      const { clubId, index } = action.payload;
      const getClubEventsListByIdResponse: ApiResponse<GetClubEventsListByIdResponse> =
        yield call(api.getClubEventsListByIdGet, { clubId, index }, token);
      if (
        getClubEventsListByIdResponse.ok &&
        getClubEventsListByIdResponse.data
      ) {
        const responseData = getClubEventsListByIdResponse.data;
        yield put(UserActions.getClubEventsListById.success(responseData));
      } else {
        _.set(
          error,
          "description",
          getClubEventsListByIdResponse.data?.message
        );
        _.set(error, "code", getClubEventsListByIdResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.getClubEventsListById.failure(extractError(error)));
    }
  }
}

function* sendNotificationRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.sendNotification.request.match(action)) {
    try {
      const error = {};
      const { birthDay, sex, title, msg } = action.payload;
      const sendNotificationResponse: ApiResponse<SendNotificationResponse> =
        yield call(
          api.sendNotificationPost,
          { birthDay, sex, title, msg },
          token
        );
      if (sendNotificationResponse.ok && sendNotificationResponse.data) {
        const responseData = sendNotificationResponse.data;
        yield put(UserActions.sendNotification.success(responseData));
      } else {
        _.set(error, "description", sendNotificationResponse.data?.message);
        _.set(error, "code", sendNotificationResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.sendNotification.failure(extractError(error)));
    }
  }
}

function* getSelfRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.getSelf.request.match(action)) {
    try {
      const error = {};
      const getSelfResponse: ApiResponse<GetSelfResponse> = yield call(
        api.getSelfGet,
        token
      );
      if (getSelfResponse.ok && getSelfResponse.data) {
        const responseData = getSelfResponse.data;
        yield put(UserActions.setMyProfile(responseData));
        yield put(UserActions.getSelf.success(responseData));
      } else {
        _.set(error, 'description', getSelfResponse.data?.message);
        _.set(error, 'code', getSelfResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({error: extractError(error)}));
      yield put(UserActions.getSelf.failure(extractError(error)));
    }
  }
}

export function* UserSaga() {
  yield* [
    takeLeading(
      UserActions.tokenRelevanceCheck.request.type,
      tokenRelevanceCheck
    ),
    takeLeading(UserActions.login.request.type, loginRequest),
    takeLeading(UserActions.logout.request.type, logout),
    takeLeading(UserActions.getPlayers.request.type, getPlayersRequest),
    takeLeading(UserActions.getSponsors.request.type, getSponsorsRequest),
    takeLeading(UserActions.editPlayers.request.type, editPlayersRequest),
    takeLeading(UserActions.createSponsor.request.type, createSponsorRequest),
    takeLeading(UserActions.editSponsors.request.type, editSponsorsRequest),
    takeLeading(UserActions.deleteSponsor.request.type, deleteSponsorRequest),
    takeLeading(UserActions.editFixtures.request.type, editFixturesRequest),
    takeLeading(UserActions.getFixtures.request.type, getFixturesRequest),
    takeLeading(UserActions.getClubs.request.type, getClubsRequest),
    takeLeading(UserActions.getSocial.request.type, getSocialRequest),
    takeLeading(UserActions.editSocial.request.type, editSocialRequest),
    takeLeading(UserActions.deleteClub.request.type, deleteClubRequest),
    takeLeading(UserActions.getEvents.request.type, getEventsRequest),
    takeLeading(UserActions.createEvent.request.type, createEventRequest),
    takeLeading(UserActions.deleteEvent.request.type, deleteEventRequest),
    takeLeading(UserActions.editEvent.request.type, editEventRequest),
    takeLeading(UserActions.voteSearch.request.type, voteSearchRequest),
    takeLeading(UserActions.getPredictions.request.type, getPredictionsRequest),
    takeLeading(UserActions.editClub.request.type, editClubRequest),
    takeLeading(
      UserActions.sendNotification.request.type,
      sendNotificationRequest
    ),
    takeLeading(UserActions.getUsers.request.type, getUsersRequest),
    takeLeading(
      UserActions.getClubEventsListById.request.type,
      getClubEventsListByIdRequest
    ),

    takeLeading(
      UserActions.deleteEventMember.request.type,
      deleteEventMemberRequest
    ),

    takeLeading(
      UserActions.deleteClubMember.request.type,
      deleteClubMemberRequest
    ),
    takeLeading(UserActions.deleteUser.request.type, deleteUserRequest),
    takeLeading(
      UserActions.deleteClubEvent.request.type,
      deleteClubEventRequest
    ),
    takeLeading(UserActions.getClubEvents.request.type, getClubEventsRequest),
    takeLeading(
      UserActions.getClubEventsById.request.type,
      getClubEventsByIdRequest
    ),
    takeLeading(UserActions.getClubMembers.request.type, getClubMembersRequest),
    takeLeading(
      UserActions.getEventMembers.request.type,
      getEventMembersRequest
    ),
    takeLeading(UserActions.getSelf.request.type, getSelfRequest)
  ];
}
