//import { Navigation } from 'react-native-navigation';
import { buffers, eventChannel } from 'redux-saga';
import { call, spawn, take } from 'redux-saga/effects';
// import { getLanguages } from 'react-native-i18n';
//import { initI18n } from '~/config/i18n';
//import { ClientSaga } from '~/logic/client/ClientSagas';
//import { ProductSaga } from '~/logic/product/ProductSagas';
//import { UserActions } from '~/logic/user/UserRedux';
import { UserSaga } from '../logic/user/UserSagas';
//import { UserInteractionSaga } from '~/logic/userInteraction/UserInteractionSagas';
// import { getConfig } from '~/config/Config';
//import { registerScreens, showDefaultScreen } from '~/screens/RegisterScreens';
//import { getTokenFromAsyncStorage, getUserIdFromAsyncStorage } from './../logic/asyncStorage/asyncStorageHelper';
import { RootStore, store } from './store';

const APP_LAUNCH = 'ruh_admin_web';

/* const loadDataFromAsyncStorage = async () => {
  const token = await getTokenFromAsyncStorage();
  const userId = await getUserIdFromAsyncStorage();
  if (token && userId) { // Позже добавить проверку актуальности токена
    store.dispatch(UserActions.storeTokenToRedux(token));
    store.dispatch(UserActions.storeUserIdToRedux(userId));
  }
} */

function createNavigationChannel() {
  return eventChannel(emit => {
    emit(APP_LAUNCH);
    return () => {};
  }, buffers.expanding(0));
}
const navigationChannel = createNavigationChannel();

function* applicationStart(store: RootStore) {
  try {
    while (true) {
      const navigationEvent = yield take(navigationChannel);
      console.log(navigationEvent);
    }
  } catch (e) {}
}
function* rootSaga(store: RootStore) {
  const sagas = [applicationStart, UserSaga];

  yield* sagas.map((saga, index) =>
    spawn(function* sagaGuard() {
      while (true) {
        try {
          if (index === 0) {
            yield call(saga, store);
          } else {
            yield call(saga);
          }
          break;
        } catch (e) {
          console.log(e);
        }
      }
    }),
  );
}

export { rootSaga };