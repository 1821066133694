import { useSelector, useDispatch } from "react-redux";
import { UserActions } from "../logic/user/UserRedux";
import { useState, useEffect } from "react";
import { userSelector } from "../logic/user/UserSelectors";
import {
  Card,
  Table,
  Stack,
  Button,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TextField,
} from "@material-ui/core";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { UserMoreMenu3 } from "../components/_dashboard/user";
import { UserListHead } from "../components/_dashboard/user";
import Searchbar from "../layouts/dashboard/Searchbar";

// ----------------------------------------------------------------------

export default function Users() {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [requestId, setRequestId] = useState("");
  const [userData, setUserData] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [page, setPage] = useState(0);

  const {
    getUsers: { data: getUsersData, fetching: getUsersFetching },
  } = useSelector(userSelector);

  const handleSearchChange = (event) => {
    setUserInput(event.target.value);
  };

  const deleteUserHandler = (requestId) => {
    const i = requestId;
    const ide = getUsersData.users[i]._id;
    dispatch(
      UserActions.deleteUser.request({
        userId: ide,
      })
    );
  };

  // let unix_timestamp = 1549312452;

  const getTime = (unix_timestamp) => {
    var a = new Date(unix_timestamp * 1000);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time =
      date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
    return time;
  };

  const handleLoadEarlier = () => {
    dispatch(UserActions.getUsers.request({ index: page + userData.length }));
    setPage(page + userData.length);
  };

  useEffect(() => {
    dispatch(UserActions.getUsers.request({ index: 0 }));
    // () => {
    //   dispatch(UserActions.getUsers.success(undefined));
    // };
  }, []);

  useEffect(() => {
    if (getUsersData !== undefined) {
      if (userData.length > 0 && page > 0) {
        setUserData([...userData, ...getUsersData.users]);
      } else {
        setUserData(getUsersData.users);
      }
    }
  }, [getUsersData]);

  const inputStyle2 = {
    display: "flex",
    outline: "none",
    width: 200,
  };

  const buttonStyle = {
    display: "flex",
    outline: "none",
    marginRight: 10,
    alignSelf: "center",
  };

  const buttonSt = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
  };

  const TABLE_HEAD = [
    { id: "User", label: "User", alignRight: false },
    { id: "Id", label: "Id", alignRight: false },
    { id: "Email", label: "Email", alignRight: false },
    { id: "Activated", label: "Activated", alignRight: false },
    { id: "Time", label: "Time", alignRight: false },
  ];

  return (
    <>
      <Page title="Ruh | Users">
        {/* <Searchbar /> */}

        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}>
            {getUsersData !== undefined && (
              <Typography variant="h4" gutterBottom>
                {getUsersData.usersQuantity + " Юзеров"}
              </Typography>
            )}
            <TextField
              value={userInput}
              onChange={handleSearchChange}
              style={inputStyle2}
              id="standard-basic"
              placeholder={"Search..."}
            />
          </Stack>

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} />
                  {userData !== undefined ? (
                    <TableBody>
                      {userData
                        .filter((itm: any) =>
                          itm.name
                            .toLowerCase()
                            .includes(userInput.toLowerCase())
                        )
                        .map((item, index) => {
                          // const { _id, name, origPhoto } = row;
                          const isItemSelected =
                            selected.indexOf(item.name) !== -1;

                          return (
                            <TableRow
                              hover
                              key={item._id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}>
                              <>
                                <TableCell align="left"></TableCell>
                                <TableCell>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}>
                                    <Avatar
                                      alt={item.name}
                                      src={item.origPhoto}
                                    />
                                    <Typography variant="subtitle2" noWrap>
                                      {item.name}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">{item._id}</TableCell>
                                <TableCell align="left">{item.email}</TableCell>
                                <TableCell align="left">
                                  {item.secret.activated.toString()}
                                </TableCell>
                                <TableCell align="left">
                                  {getTime(item._apperDate)}
                                </TableCell>

                                <TableCell align="right">
                                  <UserMoreMenu3
                                    onDelete={() => {
                                      setRequestId(index);
                                      deleteUserHandler(index);
                                    }}
                                  />
                                </TableCell>
                              </>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
          <div style={buttonSt}>
            <Button
              onClick={() => handleLoadEarlier()}
              variant="contained"
              style={buttonStyle}
              // startIcon={<Icon icon={plusFill}
              //  />}
            >
              Load more
            </Button>
          </div>
        </Container>
      </Page>
    </>
  );
}
