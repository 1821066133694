import { useSelector, useDispatch } from "react-redux";
import { UserActions } from "../logic/user/UserRedux";
import { useState, useEffect } from "react";
import { userSelector } from "../logic/user/UserSelectors";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
} from "@material-ui/core";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import { UserListHead } from "../components/_dashboard/user";
import { makeStyles, withStyles } from "@material-ui/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";

// ----------------------------------------------------------------------

export default function Predictions() {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [userData, setUserData] = useState();
  const [userDataFuture, setUserDataFuture] = useState();
  const [predictionss, setPredictions] = useState();
  const [voteData, setVoteData] = useState();

  const {
    getFixtures: { data: getFixturesData, fetching: getFixturesFetching },
    voteSearch: { data: voteSearchData, fetching: voteSearchFetching },
    getPredictions: {
      data: getPredictionsData,
      fetching: getPredictionsFetching,
    },
  } = useSelector(userSelector);

  useEffect(() => {
    dispatch(UserActions.getFixtures.request({ index: 0 }));
  }, []);

  useEffect(() => {
    if (getFixturesData?.past) {
      let i = [...getFixturesData?.past];
      const current = getFixturesData.future[0];
      i[i.length] = current;
      
      setUserData(i);
    }
  }, [getFixturesData]);

  useEffect(() => {
    if (voteSearchData?.votes) {
      setVoteData(voteSearchData.votes);
    }
  }, [voteSearchData]);

  useEffect(() => {
    if (getPredictionsData?.prediction.votes) {
      setPredictions(getPredictionsData.prediction.votes);
    }
  }, [getPredictionsData]);

  const getData = (fixtureId) => {
    dispatch(UserActions.getPredictions.request({ fixtureId: fixtureId }));
    dispatch(
      UserActions.voteSearch.request({
        fixture: fixtureId,
        sortBy: "fixture",
        sort: -1,
      })
    );
  };

  const TABLE_HEAD = [
    { id: "Percent", label: "Percent", alignRight: false },
    { id: "Ruh", label: "Ruh Score", alignRight: false },
    { id: "Enemy", label: "Enemy Score", alignRight: false },
  ];

  const TABLE_HEAD2 = [
    { id: "Player", label: "Player", alignRight: false },
    { id: "Percent", label: "Percent", alignRight: false },
    { id: "Quantity", label: "Quantity", alignRight: false },
  ];

  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
  }));

  const classes = useStyles();
  const [age, setAge] = useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
    getData(event.target.value);
  };

  return (
    <>
      <Page title="Ruh | Predictions">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}>
            <Typography variant="h4" gutterBottom>
              Прогноз на Матч
            </Typography>
            {userData !== undefined ? (
              <div>
                <FormControl className={classes.margin}>
                  <NativeSelect
                    id="demo-customized-select-native"
                    value={age}
                    onChange={handleChange}
                    input={<BootstrapInput />}>
                    <option aria-label="None" value="" />

                    {userData.map((item, index) => {
                      return (
                        <option value={item._id}>
                          {item.teams.away.name + " vs " + item.teams.home.name}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </FormControl>
              </div>
            ) : null}
          </Stack>

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} />
                  {predictionss !== undefined ? (
                    <TableBody>
                      {predictionss.map((row, index) => {
                        const { name, _id, id, percent, friend, enemy } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}>
                            <>
                              <TableCell align="left"></TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none">
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}>
                                  <Avatar
                                    alt={_id}
                                    src={
                                      "https://www.iconbunny.com/icons/media/catalog/product/3/5/352.9-football-player-icon-iconbunny.jpg"
                                    }
                                  />
                                  <Typography variant="subtitle2" noWrap>
                                    {percent + "%"}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">{friend}</TableCell>
                              <TableCell align="left">{enemy}</TableCell>
                            </>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Container>

        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            marginTop={10}
            mb={5}>
            <Typography variant="h4" gutterBottom>
              Прогноз на Игроков
            </Typography>
          </Stack>
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD2} />
                  {voteData !== undefined ? (
                    <TableBody>
                      {voteData.map((row, index) => {
                        const { id, name, player, total, percent, quantity } =
                          row;
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}>
                            <>
                              <TableCell align="left"></TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none">
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}>
                                  <Avatar
                                    alt={name}
                                    src={
                                      player.actualPhoto
                                        ? player.actualPhoto
                                        : player.photo
                                    }
                                  />
                                  <Typography variant="subtitle2" noWrap>
                                    {player.name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                {percent + "%"}
                              </TableCell>
                              <TableCell align="left">
                                {quantity + "/" + total}
                              </TableCell>
                            </>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Container>
      </Page>
    </>
  );
}
