import { filter } from "lodash";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { useSelector, useDispatch } from "react-redux";
import { UserActions } from "../logic/user/UserRedux";
import { memo, useState, useEffect } from "react";
import { userSelector } from "../logic/user/UserSelectors";

import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableCell,
  Container,
  Typography,
  TextField,
  Modal,
} from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Page from "../components/Page";
import { UserMoreMenu } from "../components/_dashboard/user";
import USERLIST from "../_mocks_/user";
import { UserListHead } from "../components/_dashboard/user";

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default function Partners() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userDataOld, setUserDataOld] = useState();
  const [userData, setUserData] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModal1, setIsOpenModal1] = useState(false);
  const [partnerImage, setImage] = useState("");
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [partnerIndex, setPartnerIndex] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const dispatch = useDispatch();
  const {
    getSponsors: { data: getSponsorsData, fetching: getSponsorsFetching },
    editSponsors: { data: editSponsorsData, fetching: editSponsorsFetching },
  } = useSelector(userSelector);

  useEffect(() => {
    dispatch(UserActions.getSponsors.request({ index: 0 }));
  }, []);

  useEffect(() => {
    if (getSponsorsData?.sponsors[0].sponsors) {
      setUserData(getSponsorsData.sponsors[0].sponsors);
      setUserDataOld(getSponsorsData.sponsors[0].sponsors);
    }
  }, [getSponsorsData]);

  useEffect(() => {
    if (getSponsorsData?.sponsors[0].sponsors) {
      setUserData(getSponsorsData.sponsors[0].sponsors);
      setUserDataOld(getSponsorsData.sponsors[0].sponsors);
    }
  }, [editSponsorsData]);

  useEffect(() => {
    if (userData === userDataOld) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [userData]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(userData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setUserData(items);
  }
  const handleImageChange = (event) => {
    setImage(event.target.value);
  };
  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const createPartner = (partnerImage, url, name) => {
    let i = [...userData];
    i.push({
      name: name,
      url: url,
      image: partnerImage,
    });
    dispatch(
      UserActions.editSponsors.request({
        id: "613ca412d7dcca43fab9d1e2",
        sponsors: i,
      })
    );
    setUrl("");
    setName("");
    setImage("");
  };

  const editPartner = (index, partnerImage, url, name) => {
    let i = [...userData];
    i.splice(index, 1, {
      name: name ? name : userData[index].name,
      url: url ? url : userData[index].url,
      image: partnerImage ? partnerImage : userData[index].image,
    });
    dispatch(
      UserActions.editSponsors.request({
        id: "613ca412d7dcca43fab9d1e2",
        sponsors: i,
      })
    );
    setUrl("");
    setName("");
    setImage("");
  };

  const deletePartner = (index) => {
    let i = [...userData];
    i.splice(index, 1);
    dispatch(
      UserActions.editSponsors.request({
        id: "613ca412d7dcca43fab9d1e2",
        sponsors: i,
      })
    );
  };

  const savePartner = () => {
    dispatch(
      UserActions.editSponsors.request({
        id: "613ca412d7dcca43fab9d1e2",
        sponsors: userData,
      })
    );
  };

  const divStyle = {
    display: "flex",
    justifyContent: "center",
    outline: "none",
    flexDirection: "column",
    padding: 40,
  };
  const divStyle2 = {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    outline: "none",
  };

  const inputStyle = {
    display: "flex",
    outline: "none",
  };

  const inputStyle2 = {
    display: "flex",
    outline: "none",
    paddingBottom: 10,
    paddingTop: 10,
  };

  const buttonStyle = {
    display: "flex",
    outline: "none",
    marginRight: 10,
  };

  const TABLE_HEAD = [
    { id: "Partner", label: "Partner", alignRight: false },
    { id: "Link", label: "Link", alignRight: false },
  ];

  return (
    <>
      <Page title="Ruh | Partners">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}>
            <Typography variant="h4" gutterBottom>
              Партнеры
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between">
              <Button
                onClick={() => {
                  setIsOpenModal1(true);
                }}
                variant="contained"
                style={buttonStyle}
                startIcon={<Icon icon={plusFill} />}>
                New Partner
              </Button>
              <Button
                disabled={buttonDisabled}
                onClick={() => savePartner()}
                variant="contained">
                Save
              </Button>
            </Stack>
          </Stack>

          <Card>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <>
                    {userData !== undefined ? (
                      <Table
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        <UserListHead padding={"none"} headLabel={TABLE_HEAD} />

                        {userData.map((row, index) => {
                          const { _id, name, url, image } = row;
                          const isItemSelected = selected.indexOf(name) !== -1;
                          return (
                            <Draggable
                              key={_id}
                              draggableId={_id}
                              index={index}>
                              {(provided) => (
                                <TableRow
                                  hover
                                  key={index}
                                  tabIndex={-1}
                                  role="checkbox"
                                  selected={isItemSelected}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}>
                                  <TableCell align="left">
                                    {index + 1}
                                  </TableCell>

                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="none">
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}>
                                      <Avatar alt={name} src={image} />
                                      <Typography variant="subtitle2" noWrap>
                                        {name}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell align="left">{url}</TableCell>
                                  <TableCell align="right">
                                    <UserMoreMenu
                                      onDelete={() => {
                                        deletePartner(index);
                                      }}
                                      onEdit={() => {
                                        setIsOpenModal(true);
                                        setPartnerIndex(index);
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              )}
                            </Draggable>
                          );
                        })}
                      </Table>
                    ) : null}
                  </>
                )}
              </Droppable>
            </DragDropContext>
          </Card>
        </Container>
      </Page>
      <Modal
        style={divStyle2}
        open={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <Card style={divStyle} elevation={3}>
          {/* <TextField type={"file"} style={inputStyle2} id="standard-basic" /> */}
          <TextField
            value={partnerImage}
            onChange={handleImageChange}
            style={inputStyle2}
            id="standard-basic"
            label="Image Link"
          />
          <TextField
            value={name}
            onChange={handleNameChange}
            style={inputStyle}
            id="standard-basic"
            label="Partner Name"
          />
          <TextField
            value={url}
            onChange={handleUrlChange}
            style={inputStyle2}
            id="standard-basic"
            label="Link"
          />
          <Button
            fullWidth
            onClick={() => {
              editPartner(partnerIndex, partnerImage, url, name);
              setIsOpenModal(false);
            }}
            target="_blank"
            variant="contained">
            Save
          </Button>
        </Card>
      </Modal>
      <Modal
        style={divStyle2}
        open={isOpenModal1}
        onClose={() => {
          setIsOpenModal1(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <Card style={divStyle} elevation={3}>
          <TextField
            value={partnerImage}
            onChange={handleImageChange}
            style={inputStyle2}
            id="standard-basic"
            label="Image Link"
          />
          <TextField
            value={name}
            onChange={handleNameChange}
            style={inputStyle}
            id="standard-basic"
            label="Partner Name"
          />
          <TextField
            value={url}
            onChange={handleUrlChange}
            style={inputStyle2}
            id="standard-basic"
            label="Link"
          />
          <Button
            fullWidth
            onClick={() => {
              createPartner(partnerImage, url, name);
              setIsOpenModal1(false);
            }}
            target="_blank"
            variant="contained">
            Add Partner
          </Button>
        </Card>
      </Modal>
    </>
  );
}
