import { useSelector, useDispatch } from "react-redux";
import { UserActions } from "../logic/user/UserRedux";
import { useState, useEffect, useRef } from "react";
import { userSelector } from "../logic/user/UserSelectors";
import { fDate } from "src/utils/formatTime";
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import {
  UserMoreMenu3,
  UserMoreMenu,
  UserMoreMenu4,
  UserMoreMenu5,
} from "../components/_dashboard/user";
import { UserListHead } from "../components/_dashboard/user";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import { Icon } from "@iconify/react";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles, withStyles } from "@material-ui/styles";

// ----------------------------------------------------------------------

export default function ClubEvents() {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [requestId, setRequestId] = useState("");
  const [userData, setUserData] = useState();
  const [clubData, setClubData] = useState();
  const [name, setName] = useState();
  const [isOpenModal2, setIsOpenModal2] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [userInput2, setUserInput2] = useState("");

  const ref = useRef(null);

  const {
    getClubEvents: { data: getClubEventsData, fetching: getClubEventsFetching },
    getEventMembers: {
      data: getEventMembersData,
      fetching: getEventMembersFetching,
    },
    getClubs: { data: getClubsData, fetching: getClubsFetching },
    getClubEventsListById: {
      data: getClubEventsListByIdData,
      fetching: getClubEventsListByIdFetching,
    },
  } = useSelector(userSelector);

  useEffect(() => {
    dispatch(UserActions.getClubEvents.request({ index: 0 }));
    dispatch(UserActions.getClubs.request({ index: 0 }));
  }, []);

  useEffect(() => {
    if (getClubEventsData?.events) {
      setUserData(getClubEventsData.events);
    }
  }, [getClubEventsData]);

  useEffect(() => {
    if (getClubsData?.clubs) {
      setClubData(getClubsData.clubs);
    }
  }, [getClubsData]);

  useEffect(() => {
    if (getClubEventsListByIdData?.events) {
      setUserData(getClubEventsListByIdData?.events);
    }
  }, [getClubEventsListByIdData]);

  const handleSearchChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleSearchChange2 = (event) => {
    setUserInput2(event.target.value);
  };

  const getEventMembersHandler = (requestId) => {
    const i = requestId;
    const ide = getClubEventsData.events[i]._id;
    const cde = getClubEventsData.events[i].club._id;

    dispatch(
      UserActions.getEventMembers.request({
        clubId: cde,
        eventId: ide,
        index: "0",
      })
    );
  };

  const deleteClubEventHandler = (requestId) => {
    const i = requestId;
    const ide = getClubEventsData.events[i]._id;
    const cde = getClubEventsData.events[i].club._id;

    dispatch(
      UserActions.deleteClubEvent.request({
        clubId: cde,
        eventId: ide,
      })
    );
  };

  const deleteEventMemberHandler = (clubIdIndex, memberIdIndex) => {
    const i = clubIdIndex;
    const mi = memberIdIndex;
    const cde = getClubEventsData.events[i].club._id;
    const ide = getClubEventsData.events[i]._id;
    const mide = getEventMembersData.members[mi]._id;
    dispatch(
      UserActions.deleteEventMember.request({
        clubId: cde,
        eventId: ide,
        userId: mide,
      })
    );
  };

  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
  }));
  const [age, setAge] = useState("");

  const getData = (id) => {
    dispatch(
      UserActions.getClubEventsListById.request({ clubId: id, index: "0" })
    );
  };

  const classes = useStyles();
  const handleChange = (event) => {
    setAge(event.target.value);
    getData(event.target.value);
  };

  const divStyle = {
    display: "flex",
    justifyContent: "center",
    outline: "none",
    flexDirection: "column",
    padding: 40,
  };
  const divStyle2 = {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    outline: "none",
  };

  const inputStyle = {
    display: "flex",
    outline: "none",
  };

  const inputStyle2 = {
    display: "flex",
    outline: "none",
  };

  const inputStyle3 = {
    display: "flex",
    outline: "none",
    paddingTop: 20,
    paddingLeft: 14,
    paddingRight: 14,
    paddingBottom: 15,
  };

  const div4 = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  };

  const divStyle5 = {
    outline: "none",
    maxWidth: 400,
  };

  const TABLE_HEAD = [
    { id: "Event", label: "Event Name", alignRight: false },
    { id: "Kind", label: "Kind", alignRight: false },
    { id: "Time", label: "Time", alignRight: false },
    { id: "Member", label: "Total Members", alignRight: false },
  ];

  return (
    <>
      <Page title="Ruh | Events">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}>
            <Typography variant="h4" gutterBottom>
              {"Ивенты"}
            </Typography>
            <div style={div4}>
              {clubData !== undefined ? (
                <div>
                  <FormControl className={classes.margin}>
                    <NativeSelect
                      id="demo-customized-select-native"
                      value={age}
                      onChange={handleChange}
                      input={<BootstrapInput />}>
                      <option aria-label="None" value="" />

                      {clubData.map((item, index) => {
                        return <option value={item._id}>{item.name}</option>;
                      })}
                    </NativeSelect>
                  </FormControl>
                </div>
              ) : null}
              <TextField
                value={userInput}
                onChange={handleSearchChange}
                style={inputStyle2}
                id="standard-basic"
                placeholder={"Search..."}
              />
            </div>
          </Stack>

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} />
                  {userData !== undefined ? (
                    <TableBody>
                      {userData
                        .filter((itm: any) =>
                          itm.title
                            .toLowerCase()
                            .includes(userInput.toLowerCase())
                        )
                        .map((row, index) => {
                          const {
                            _id,
                            title,
                            kind,
                            origPhoto,
                            time,
                            totalMembers,
                          } = row;
                          const isItemSelected = selected.indexOf(name) !== -1;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}>
                              <>
                                <TableCell align="left"></TableCell>
                                <TableCell>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}>
                                    <Avatar alt={name} src={origPhoto} />
                                    <Typography variant="subtitle2" noWrap>
                                      {title}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">{kind}</TableCell>
                                <TableCell align="left">{time}</TableCell>
                                <TableCell align="left">
                                  {totalMembers}
                                </TableCell>

                                <TableCell align="right">
                                  <UserMoreMenu5
                                    onDelete={() => {
                                      setRequestId(index);
                                      deleteClubEventHandler(index);
                                    }}
                                    onMembers={() => {
                                      setRequestId(index);
                                      getEventMembersHandler(index);
                                      setIsOpenModal2(true);
                                    }}
                                  />
                                </TableCell>
                              </>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Container>
      </Page>

      <Modal
        style={divStyle2}
        open={isOpenModal2}
        onClose={() => {
          setIsOpenModal2(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <Container style={divStyle5}>
          <Card>
            <Scrollbar>
              <TableContainer sx={{ maxWidth: 400 }}>
                <TextField
                  value={userInput2}
                  onChange={handleSearchChange2}
                  style={inputStyle3}
                  id="standard-basic"
                  placeholder={"Search..."}
                />
                <Table>
                  {/* <UserListHead headLabel={TABLE_HEAD2} /> */}
                  {getEventMembersData !== undefined ? (
                    <TableBody>
                      {getEventMembersData.members
                        .filter((itm: any) =>
                          itm.name
                            .toLowerCase()
                            .includes(userInput2.toLowerCase())
                        )
                        .map((row, index) => {
                          const { _id, name, surname, origPhoto } = row;
                          const isItemSelected = selected.indexOf(name) !== -1;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}>
                              <>
                                <TableCell>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}>
                                    <Avatar alt={name} src={origPhoto} />
                                    <Typography variant="subtitle2" noWrap>
                                      {name + " " + surname}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="right">
                                  <IconButton
                                    onClick={() => {
                                      deleteEventMemberHandler(
                                        requestId,
                                        index
                                      );
                                      getEventMembersHandler(requestId);
                                    }}>
                                    <Icon
                                      icon={trash2Outline}
                                      width={20}
                                      height={20}
                                    />
                                  </IconButton>
                                </TableCell>
                              </>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Container>
      </Modal>
    </>
  );
}
